<template>
  <div class="goldenSentence">
    <div class="header_box">
      <!-- 搜索 -->
      <div class="search_box">
        <input
          class="inp"
          type="text"
          placeholder="请输入内容搜索"
          v-model="searchValue"
          @keyup.enter="search"
        />
        <div class="search_btn" @click="search()">
          <img :src="require('assets/images/idiom.png')" alt="" />
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>
      </div>
    </div>
    <goldenSentenceDilets
      @pageChange="pageChange"
      @sortClick="sortClick"
      :isPage="isPage"
      :listData="listData"
      :showPage="showPage"
      :labelId="labelId"
      :stair="stair"
    />
  </div>
</template>

<script>
import goldenSentenceDilets from "./goldenSentenceDilets";
import {
  getProductListGroup,
  gettemplateAndSayingLibrary,
  getlibrarylist,
} from "api/dropDownList";
import { getToken } from "@/utils/auth";
import qs from "qs";
import {
  oftenWordSearch,
  goldensentenceCollectList,
} from "api/goldenSentenceIndex.js";
import {
  findSelectedLib,
  findOftenLibraryType,
  findOftenWord,
  addTemplateCollect,
  addTemplateHot,
  logAdd,
} from "api/search";
export default {
  name: "goldenSentence",
  props: {
    isHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentClickItemVip: null,
      // isHide: false,
      isflag: true,
      isMark: false,
      showPage: true,
      isAn: false,
      labelId: "JJdangdesd00001",
      openList: [],
      ChildrenInfoShow: false,
      ChildrenInfo: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo1: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo2: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      stair: "JJhexjj00001",
      pageUuid: "",
      parentName: "",
      // 是否购买
      checked: 0,
      // 选中购买类型
      buyTypeI: 0,
      // 购买选择数据
      ChooseBuy: [
        {
          id: 2,
          price: "98",
          title: "写手智能科技VIP/SVIP会员",
          text: "开通会员全部金句可免费浏览，更多权益点击购买了解详情。",
        },
      ],
      // 购买弹框
      buyDialog: false,
      // 库列表
      bankList: [],
      // 收藏
      collect: [],
      // 需要展开的数组
      openeds: [],
      // 搜索关键字
      searchValue: "",

      libraryId: "",

      // 模板列表
      listData: [],
      // 页码
      pageNum: 1,
      busy: false,
      end: false,
      // 空
      isKong: false,
      islLine: false,
      isshow: false,
      isPage: false,
      // 左侧点击数据
      htmlText: {},
      isTheme_box: false,
      sortType: 1,
      sortVal: "desc",
      total: 0,
    };
  },
  methods: {
    async search() {
      this.pageNum = 1;
      this.pageUuid = "";
      await this.getList();
      // if (this.listData.length == 0) {
      //   this.$refs["thirdPartySearch"].getBooksHTML(this.searchValue);
      // }
    },
    pageChange(val, isFlag) {
      if (isFlag == 1) {
        if (this.currentClickItemVip && this.currentClickItemVip.isVip != 1) {
          this.memberRenewal(this.currentClickItemVip);
          return;
        }
        this.pageNum = val;
        this.getList(this.labelId);

        this.$nextTick(() => {
          let PageId = document.querySelector("#main");

          PageId.scrollTop = 0;
        });
      } else {
        this.pageNum = val;
        this.getcollectList();
      }
    },
    hideSidebar() {
      this.$emit("hideSidebar");
    },
    async getList(labelId) {
      this.showPage = true;
      this.isKong = false;
      const params = {
        page: this.pageNum,
        key: this.searchValue,
        pageUuid: this.pageUuid,
        labelId: labelId ? labelId : "JJdangdesd00001",
        sortType: this.sortType == 1 ? "time_sort" : "hot",
        sort: this.sortVal,
      };

      const res = await oftenWordSearch(params);
      if (res.code != 200) {
        this.end = true;
        this.$message({
          message: res.message,
        });
        return;
      }
      this.total = res.data.totalNum;
      this.pageUuid = res.data.page_uuid;

      this.listData = res.data.data;
      if (this.listData.length != 0) {
        this.isflag == true;
      } else {
        this.isflag == false;
      }
    },
    sortClick(val) {
      this.sortType = val.sortType;
      this.sortVal = val.sortVal;
      this.pageNum = 1;
      this.pageUuid = "";
      this.getList();
    },
  },
  created() {
    // this.getList();
  },
  components: {
    goldenSentenceDilets,
  },
};
</script>

<style lang="scss" scoped>
.header_box {
  display: flex;
  align-items: center;
  padding: 20px 30px 0;
  & > div:first-child {
    margin-right: 10px;
  }
}
// 搜索
.search_box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #ff6900;
  width: 100%;
  .inp {
    height: 26px;
    border-left: 1px solid #e4e4e4;
    padding-left: 20px;
    font-size: $font-size-medium;
    font-family: PingFang SC;
    font-weight: 500;
    color: $color-text-default;
    flex: 1;
  }
  .search_btn {
    height: 40px;
    padding: 0 10px;
    background: #ff6900;
    @include flex-center();
    // border-radius: 0 5px 5px 0;
    cursor: pointer;
    > img:first-child {
      width: 52px;
      height: 22px;
      margin: 0 10px;
    }
  }
}
</style>
