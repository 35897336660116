<template>
  <div class="record">
    <div class="table-list" v-if="tableData.length != 0">
      <el-table :data="tableData" v-loading="loading">
        <el-table-column
          width="50"
          label="序号"
          type="index"
          align="center"
        ></el-table-column>
        <el-table-column prop="createtime" align="center" label="时间">
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <div
              :class="[
                'states',
                scope.row.status === 0
                  ? 'gray'
                  : scope.row.status === 1
                  ? 'green'
                  : 'red',
              ]"
            >
              {{
                scope.row.status === 0
                  ? "转换中"
                  : scope.row.status === 1
                  ? "转换完成"
                  : "转换失败"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <!-- <img
              v-if="scope.row.status == 1"
              :src="
                scope.row.type == 1
                  ? require('assets/images/view-kind.png')
                  : require('assets/images/view-kind.png')
              "
              @click="handleClick(scope.row)"
              style="vertical-align: middle; cursor: pointer; margin-right: 5px"
              alt=""
            />
            <span
              style="color: #4587ff; cursor: pointer; margin-right: 10px"
              v-if="scope.row.status == 1"
              @click="handleClick(scope.row)"
              >{{ scope.row.type == 1 ? "查看" : "查看" }}</span
            > -->

            <img
              v-if="scope.row.status == 1"
              :src="
                scope.row.type == 1
                  ? require('assets/images/download-blue.png')
                  : require('assets/images/download-blue.png')
              "
              @click="downLoad(scope.row)"
              style="vertical-align: middle; cursor: pointer; margin-right: 5px"
              alt=""
            />
            <span
              style="color: #4587ff; cursor: pointer"
              v-if="scope.row.status == 1"
              @click="downLoad(scope.row)"
              >下载</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="currentChange"
        :current-page="page"
        :page-size="limit"
        :pager-count="5"
        layout="total, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </div>
    <div class="kong" v-else>
      <img :src="require('assets/images/kong.png')" alt="" />
      <div>暂无汇编记录</div>
    </div>
  </div>
</template>

<script>
import { assemblyList } from "api/infoCompile";
import { isHasFreeCount } from "api/common";
export default {
  name: "Record",
  data() {
    return {
      page: 1,
      limit: 10,
      tableData: [],
      loading: false,
      pageTotal: 0,
    };
  },
  created() {
    this.initList();
  },
  methods: {
    async initList() {
      const data = {
        page: this.page,
        limit: this.limit,
      };
      this.loading = true;
      const res = await assemblyList(data);
      this.tableData = res.data.data;
      this.pageTotal = res.data.total;
      this.loading = false;
      // console.log(res);
    },
    currentChange(val) {
      this.page = val;
      this.initList();
    },
    async handleClick(item) {
      const flieName = item.assemblyUrl.split("/");
      console.log("汇编成册" + flieName[flieName.length - 1]);
      try {
        // eslint-disable-next-line
        OpenDocument2(
          "汇编成册" + flieName[flieName.length - 1],
          item.assemblyUrl
        );
      } catch (e) {
        console.log(e);
      }
    },
    // DownloadDocument
    downLoad(item) {
      // const flieName = item.assemblyUrl.split("/");
      // console.log("汇编成册" + flieName[flieName.length - 1]);
      // try {
      //   // eslint-disable-next-line
      //   DownloadDocument(
      //     "汇编成册" + flieName[flieName.length - 1],
      //     item.assemblyUrl
      //   );
      // } catch (e) {
      //   console.log(e);
      // }

      window.open(item.assemblyUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.record {
  // height: calc(100vh - 115px);
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  .kong {
    margin-top: 10%;
    text-align: center;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .table-list {
    max-height: 95%;
    overflow: auto;
    ::v-deep .el-pagination {
      width: 95%;
      box-sizing: border-box;
      text-align: center;
      // position: absolute;
      bottom: 0;
    }
    ::v-deep .el-table {
      .el-table__body-wrapper {
        .states {
          display: inline-block;
          // width: 60px;
          padding: 0 5px;
        }
        .gray {
          display: inline-block;
          background: url("~assets/images/rhombus-gray.png") no-repeat;
          background-size: 100% 100%;

          color: #999;
        }

        .green {
          // width: 100%;
          display: inline-block;
          // position: absolute;
          // height: 100%;
          background: url("~assets/images/rhombus-green.png") no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-size: 12px;
        }
        .red {
          // width: 100%;
          display: inline-block;
          // position: absolute;
          // height: 100%;
          background: url("~assets/images/rhombus-red.png") no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
