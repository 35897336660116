<template>
  <div class="infoCompile">
    <div class="infocompileIndex" id="infocompileIndex">
      <div class="search_box">
        <div class="search">
          <div class="search_left">
            <el-select
              @change="selectChange"
              v-model="selectTheme"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <input
            class="inp"
            v-model="searchValue"
            type="text"
            :placeholder="placeholderText"
            @click.stop="handleFocus"
            @keyup.enter="search"
          />
          <div class="search_btn" @click="navInfoCompile(searchValue)">
            <!-- <i class="search_icon"></i> -->
            <img :src="require('assets/images/idiom.png')" alt="" />
            <img :src="require('assets/images/included_search.png')" alt="" />
          </div>
        </div>

        <div class="historyList" v-show="isShowHistoryList">
          <div
            v-for="(item, index) in historyList"
            :key="index"
            @click="
              searchValue = item;
              isShowHistoryList = false;
              navInfoCompile(searchValue);
            "
          >
            {{ item }}
          </div>
          <div class="option">
            <span @click="delHistory">删除历史</span>
          </div>
        </div>
      </div>

      <div class="infocompileIndex-content">
        <div
          class="infocompileIndex-content-breadcrumb"
          v-if="breadcrumb.length != 0"
        >
          <div
            class="infocompileIndex-content-breadcrumb-item"
            v-for="(item, index) in breadcrumb"
            :key="index"
          >
            <el-tooltip
              class="item"
              :content="item.title"
              effect="light"
              placement="bottom"
            >
              <span @click="closeWord(item)" class="item-title">
                <el-button :class="item.title == itemflag ? 'color' : ''">{{
                  item.title
                }}</el-button>
              </span>
            </el-tooltip>
            <span
              @click="closeWord(item)"
              class="item-num"
              :class="item.title == itemflag ? 'color' : ''"
              >{{ item.num }}</span
            >
            <i @click="close(index)" class="el-icon-close"></i>
          </div>
        </div>

        <div class="infocompileIndex-content-list" v-show="!flag">
          <div
            class="infocompileIndex-content-list-title"
            v-if="infoList.length != 0"
          >
            <el-checkbox
              v-show="selectTheme != '5' && selectTheme != '6'"
              v-model="checked"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <div
              class="infocompileIndex-content-list-title-right"
              v-show="!isCollect"
            >
              <div
                class="right-item"
                v-for="(item, index) in sortArr"
                :key="index"
                @click="handleClick(item)"
              >
                <div
                  class="right-item-text"
                  :class="item.colorSwitch == true ? 'fontColor' : ''"
                >
                  {{ item.title }}
                </div>
                <i
                  class="right-item-icon"
                  :class="item.sort == true ? 'up' : 'down'"
                ></i>
              </div>
            </div>
            <div
              v-show="selectTheme != '5' && selectTheme != '6'"
              class="infocompileIndex-content-list-title-left"
            >
              <div class="left-item" @click="addAssembly">
                <i class="left-item-icon add"></i>
                <div class="left-item-text">添加汇编</div>
              </div>
              <div class="left-item" @click="AssemblyBooks">
                <i class="left-item-icon icon"></i>
                <el-badge :value="assemblyNum" :max="99">
                  <div class="left-item-text">汇编成册</div>
                </el-badge>
              </div>
              <el-upload
                class="upload-demo"
                action=""
                :show-file-list="false"
                :http-request="importChecking"
              >
                <div class="left-item" v-show="isCollect">
                  <i class="left-item-icon download"></i>
                  <div class="left-item-text">上传文件</div>
                </div>
              </el-upload>
              <div class="left-item" v-show="isCollect" @click="goDisk">
                <i class="left-item-icon folder"></i>
                <div class="left-item-text">打开文件夹</div>
              </div>
            </div>
          </div>
          <div
            class="infocompileIndex-content-list-content"
            v-if="infoList.length != 0"
          >
            <div v-for="(v, i) in infoList" :key="i" class="content-item">
              <!-- 转载或原创图标 -->
              <!-- <i v-if="v.firstLaunch == 1" class="content-item-icon reprint"></i>
						<i v-if="v.firstLaunch == 2" class="content-item-icon starting"></i> -->
              <!-- 收藏图标 -->
              <el-tooltip
                effect="light"
                placement="left"
                popper-class="origina"
              >
                <div slot="content">
                  <div style="white-space: nowrap">
                    溯源版原创率{{ v.traceSourceEvaluation || 0 }}%
                  </div>
                  <div style="white-space: nowrap">
                    溯源时间:{{ v.traceSourceDate || "2022-01-01" }}
                  </div>
                </div>
                <div
                  v-show="v.isTraceSource"
                  class="source_version"
                  @click="findSourceVersion(v)"
                >
                  <img :src="require('assets/images/source.png')" alt="" />
                  <span>溯源版({{ v.traceSourceEvaluation || 0 }}%)</span>
                </div>
              </el-tooltip>
              <div class="title">
                <div
                  v-show="selectTheme != '5' && selectTheme != '6'"
                  class="introduce-checkBox"
                  @click="handleChecked(v, i)"
                >
                  <i
                    class="introduce-checkBox-icon"
                    :class="v.ischecked == true ? 'checkBox-checked' : ''"
                  ></i>
                  <!-- <div class="introduce-checkBox-text">标准版</div> -->
                </div>
                <div
                  class="title_box"
                  @click="clickPreview(v)"
                  v-text="v.footNotes"
                ></div>
              </div>
              <div class="content-item-text">
                <span v-html="v.nodeText"></span>
                <!-- <span @click="handleAllText(v)">查看全文</span> -->
              </div>

              <div
                class="content-item-introduce"
                v-show="selectTheme != '5' && selectTheme != '6'"
              >
                <div class="content-item-btnGroup">
                  <i
                    class="content-item-like"
                    :class="v.isMark == true ? 'like-red' : 'like'"
                    @click="collect(v)"
                  ></i>

                  <div
                    class="content-item-btnItem downloadBox"
                    @click="singleDownload(v)"
                  >
                    <i class="content-item-btnItem-icon download"></i>
                    <div class="content-item-btnItem-text">下载</div>
                  </div>

                  <!-- <div
                    class="content-item-btnItem uploadBox"
                    @click="moveTo($event, v)"
                  >
                    <i class="content-item-btnItem-icon upload"></i>
                    <div class="content-item-btnItem-text">转存</div>
                  </div> -->

                  <div
                    class="content-item-btnItem shareBox"
                    @click="shareClick(v)"
                  >
                    <i class="content-item-btnItem-icon share"></i>
                    <div class="content-item-btnItem-text">分享</div>
                  </div>

                  <div class="introduce-starBox">
                    <i class="introduce-starBox-icon"></i>
                    <div
                      class="introduce-starBox-text"
                      v-text="v.authoritative"
                    ></div>
                  </div>
                  <div class="introduce-lampBox">
                    <i class="introduce-lampBox-icon"></i>
                    <div class="introduce-lampBox-text" v-text="v.origin"></div>
                  </div>
                  <div class="introduce-timeBox">
                    <i class="introduce-timeBox-icon"></i>
                    <div
                      class="introduce-timeBox-text"
                      v-text="v.publishTime"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="flag" class="kong">
          <img :src="require('assets/images/kong.png')" />
          <div>更新中</div>
        </div>
        <!-- <div v-if="infoList.length == 0">
          <thirdPartySearch @noData="noData" ref="thirdPartySearch" />
        </div> -->
      </div>
      <div v-if="!flag" class="infocompileIndex-footer">
        <el-pagination
          v-if="infoList.length != 0"
          :layout="'total, prev, pager, next'"
          :current-page="pageNum"
          :page-size="10"
          @current-change="currentChange"
          :total="
            selectTheme == '5' || selectTheme == '6'
              ? searchValue
                ? totalNum
                : 100
              : totalNum
          "
        >
        </el-pagination>
      </div>

      <!-- 汇编成册 -->
      <el-dialog
        :modal="false"
        :close-on-click-modal="false"
        :visible.sync="compileVolumeDialog"
        title="汇编成册"
        class="compile-volume"
      >
        <div class="content">
          <div class="content-text">自动生成含目录的资料汇编</div>
        </div>
        <div class="footer">
          <div class="footer-btn" @click="handleCompileVolume">确定</div>
          <!-- <div class="footer-btn clear" @click="cleanCompile">清空汇编</div> -->
          <div class="footer-btn" @click="compileVolumeDialog = false">
            取消
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="二维码"
        :close-on-click-modal="false"
        :visible.sync="QrCodeVisible"
        @close="handleCloseQrcode"
        class="qrcode"
      >
        <div class="content">
          <div class="notes">分享二维码</div>
          <div class="imgBox">
            <div ref="qrcode_box"></div>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="汇编记录"
        class="compilations"
        :visible.sync="compilations"
        :close-on-click-modal="false"
        width="800px"
      >
        <div
          class="progress"
          :style="{ width: progress + '%' }"
          style="height: 2px; background: #409eff"
        ></div>
        <div class="option" style="margin-top: 10px">
          <el-badge :value="assemblyNum" class="item">
            <el-button size="small" @click="addCompileVolume"
              >汇编成册</el-button
            >
          </el-badge>
          <el-button
            size="small"
            style="margin-left: 20px"
            @click="
              showFileList = true;
              initSelectList();
              selectPage = 1;
            "
            >文件列表</el-button
          >
        </div>

        <record ref="record" />
      </el-dialog>
      <el-dialog
        title="文件列表"
        :modal="true"
        :visible.sync="showFileList"
        class="selectList"
        width="800px"
      >
        <el-button size="small" @click="deleteSelectAll">删除选中</el-button>
        <el-button size="small" @click="cleanCompile">清空汇编</el-button>
        <el-table
          @select="selectRow"
          @select-all="selectRow"
          v-loading="dialogLoading"
          :data="selectList"
        >
          <el-table-column
            width="50"
            type="selection"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="footNotes"
            min-width="600"
            align="center"
            label="标题"
          >
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center">
                <img
                  :src="require('assets/images/delete-blue.png')"
                  style="vertical-align: middle; cursor: pointer"
                  alt=""
                />
                <span
                  style="color: #ff6900; cursor: pointer"
                  @click="deleteSelectFile(scope.row)"
                  >删除</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="text-align: center"
          @current-change="selectCurrentChange"
          :current-page="selectPage"
          :page-size="10"
          :pager-count="5"
          layout="total, prev, pager, next, jumper"
          :total="selectTotal"
        >
        </el-pagination>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import dataAnalysisDetails from "../dataAnalysis/dataAnalysisDetails.vue";
import { findOftenLibraryType } from "api/search";
import record from "./record";
import { getToken } from "@/utils/auth";
// import Suspension from "components/Suspension";
import { getProductListGroup, getlibrarylist } from "api/dropDownList";
import {
  list,
  queryOfficicalWord,
  addAssemblys,
  getAssemblyNums,
  fileDownload,
  saveNetdisk,
  Collection,
  writerMergeWord,
  delDocTempInfo,
  findTextUrl,
  getSidebarList,
  analysisSearch,
  saveToCollect,
  querylCollectionWord,
  openLibraryFile,
  openLibraryTraceSourceFile,
  checkAssembly,
  queryAssembleWord,
  delAssembleByIds,
} from "api/infoCompile";
import { previewUrl } from "api/common";
import { directory } from "api/disk";
import QRCode from "qrcodejs2";
import qs from "qs";
export default {
  name: "infoCompile",
  data() {
    return {
      compilations: false,
      showFileList: false,
      progress: 0,
      uploadSuccess: false,
      selectPage: 1,
      selectTotal: 0,
      selectList: [],
      stair: "WKlingdrssx00001",
      currentClickItemVip: null,
      isAn: false,
      openList: [],
      tableSelectList: [],
      ChildrenInfoShow: false,
      ChildrenInfo: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo1: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo2: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },

      uploadVisible: false,
      columnType: 1, //类型  网盘传1  版权传2  原创存证3 溯源4
      parentId: 0, //父级ID
      fileType: 0, //文件类型 0全部 word1  图片2 视频3 音频4
      sidebarTtemId: -1,
      QrCodeVisible: false,
      qr: "",
      dialogLoading: false,
      currentLabelId: "",
      isCollect: false,
      sendKey: this.$route.query.key,
      newDate: "",
      isShowHistoryList: false,
      placeholderText: "让每个人都写得更好",
      historyList: [],
      btnArr: [],
      options: [
        {
          value: "1",
          label: "全文",
        },
        {
          value: "2",
          label: "标题",
        },
        // {
        //   value: "5",
        //   label: "标准段",
        // },
        // {
        //   value: "6",
        //   label: "标准句",
        // },
        {
          value: "3",
          label: "作者",
        },
        {
          value: "4",
          label: "来源",
        },
      ],
      selectTheme: "1",
      loading: false,
      value: "选项1",
      // 搜索关键字
      searchValue: "",
      breadcrumb: [],
      timer: null,
      isPlaying: 0,
      checked: [],
      //选中的数组
      checkarr: [],
      sortArr: [
        {
          title: "时间",
          sort: false,
          colorSwitch: true,
        },
        {
          title: "权威",
          sort: true,
          colorSwitch: false,
        },
        // {
        //   title: "热度",
        //   sort: true,
        //   colorSwitch: false,
        // },
      ],
      // isHide: false,
      pageCount: 10,
      pageNum: 1,
      //信息列表
      infoList: [],
      //分页密钥
      pageUuid: "",
      assemblyNum: null,
      //信息排序
      sort: "desc",
      //排序类型
      sortType: "time_sort",
      //无数据时显示
      flag: false,
      //信息菜单
      infoflag: false,
      //子菜单弹窗
      menuflag: false,
      //子菜单信息
      data: [],
      //二级菜单信息
      second: [],
      //三级菜单信息
      tertiary: [],
      //四级菜单
      four: [],
      //五级菜单
      five: [],
      //六级菜单
      six: [],
      //名称显示
      props: {
        label: "name",
        isLeaf: "leaf",
      },
      //搜索类型
      findField: 1,
      //菜单id
      meunIds: 100,
      //常选词
      itemflag: "",

      //转存网盘弹窗
      movePopup: false,
      checkData: "",
      TreeData: [],
      moveid: "",
      md5: "",
      defaultProps: {
        children: "children",
        label: "filename",
      },
      //添加常用词数量
      totalNum: "",
      //汇编成册弹窗
      compileVolumeDialog: false,
      progressTimer: null,
      labelId: "WKlingdrssx00001",
      // 子级
      // childrenList: [],
      isDetails: false,
    };
  },
  props: {
    isHide: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.$route.query.key) {
      this.searchValue = this.$route.query.key;
      this.search();
    } else {
      this.getList();
    }

    this.getAssemblyNum();
  },
  methods: {
    navInfoCompile(key) {
      //将搜索记录添加到历史列表中
      if (this.historyList.indexOf(key) == -1) {
        const historyList = localStorage.getItem("historyList");
        if (historyList) {
          this.historyList = JSON.parse(historyList);
          if (this.historyList.length >= 10) {
            this.historyList.pop();
          }
          this.historyList.unshift(key);
          localStorage.setItem("historyList", JSON.stringify(this.historyList));
        } else {
          this.historyList = [];
          this.historyList.push(key);
          localStorage.setItem("historyList", JSON.stringify(this.historyList));
        }
      }
      this.search();
    },
    handleCloseQrcode() {
      this.$refs.qrcode_box.innerHTML = "";
    },
    async getList(labelId) {
      if (labelId) {
        this.currentLabelId = labelId;
      }

      let data = {
        page: this.pageNum,
        key: this.searchValue,
        // meunIds: this.meunIds,
        pageUuid: this.pageUuid,
        sort: this.sort,
        labelId: labelId ? labelId : "WKxiyesd00001",
        // searchType: 0,
        sortType:
          this.selectTheme == 5 || this.selectTheme == 6
            ? this.sortType == "authoritative"
              ? this.sortType
              : "create_time"
            : this.sortType,
        // mapId: "",
        findField: this.selectTheme,
      };

      let res =
        this.selectTheme == 5 || this.selectTheme == 6
          ? await analysisSearch(qs.stringify(data))
          : await queryOfficicalWord(qs.stringify(data));

      if (res.code == 200) {
        if (!res.data) {
          this.infoList = [];
          return;
        }
        this.flag = false;
        this.infoList = res.data.data;
        this.totalNum = res.data.totalNum ? res.data.totalNum : res.data.total;
        this.pageUuid = res.data.page_uuid;
        this.pageCount = Math.ceil(res.data.totalNum / 11);
        if (res.data.data.length == 0) {
          this.flag = true;
        }

        // 手动添加 ischecked判断是否选中
        this.infoList.map((item) => {
          item.ischecked = item.isAssembly;
        });
      } else {
        this.$message.error(res.message);
      }
    },
    selectChange(val) {
      this.pageNum = 1;
      this.pageUuid = "";
      this.getList();
    },
    delHistory() {
      this.historyList = [];
      localStorage.removeItem("historyList");
      this.isShowHistoryList = false;
    },
    cleanCompile() {
      if (this.selectList.length == 0) {
        this.$message.error("当前文件列表为空");
        return;
      }
      this.$confirm("确定要清空汇编文件嘛?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.$store.commit("playLifeLoading", true);
        const res = await delDocTempInfo();
        this.$store.commit("playLifeLoading", false);
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "清空成功",
          });
          this.initSelectList();
          this.getAssemblyNum();
          this.checked = false;
          this.getList(this.labelId);
          this.compileVolumeDialog = false;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    // 获取汇编成册数量
    async getAssemblyNum() {
      const res = await getAssemblyNums();
      if (res.code === 200) {
        this.assemblyNum = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    handleFocus() {
      this.placeholderText = "";
      const historyList = localStorage.getItem("historyList");
      if (historyList) {
        this.historyList = JSON.parse(historyList);
        this.isShowHistoryList = true;
      } else {
        return;
      }
    },
    async handleCompileVolume() {
      // const loading = this.$createLoading("#infocompileIndex");
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      try {
        const res = await writerMergeWord();
        loading.close();
        if (res.code == 200) {
          this.progressTimer = setInterval(() => {
            if (this.progress > 80) {
              clearInterval(this.progressTimer);
            }
            let num = Math.floor(Math.random() * (15 - 10 + 1) + 5);
            this.progress += num;
          }, 2000);
          this.isPlaying++;
          this.timer = setInterval(async () => {
            this.isPlaying++;
            if (this.isPlaying > 6) {
              this.isPlaying = 0;
              clearInterval(this.timer);
            }
            let form = new FormData();
            form.append("recordId", res.data);
            const result = await checkAssembly(form);
            if (result.data.data.status == 1 || result.data.data.status == 2) {
              this.$message({
                type: "success",
                message: "汇编成功",
              });
              this.$refs["record"].initList();

              this.progress = 100;
              setTimeout(() => {
                this.progress = 0;
              }, 5000);
              clearInterval(this.timer);
              this.isPlaying = 0;
            }
          }, 5000);
          this.$message({
            type: "success",
            message: "开始汇编",
          });
          this.compileVolumeDialog = false;
        } else {
          loading.close();
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } catch (e) {
        this.$message.error("转换失败");
        this.progress = 0;
        this.isPlaying = 0;
      }
    },
    handleCheckAllChange(value) {
      this.checkarr = [];
      if (value) {
        this.infoList.map((item) => {
          item.ischecked = true;
          this.checkarr.push(item.textMd5);
        });
      } else {
        this.infoList.map((item) => {
          if (!item.isAssembly) {
            item.ischecked = false;
          }
        });
        this.checkarr = [];
      }
    },
    async deleteSelectFile(row) {
      const data = {
        ids: row.textMd5,
      };
      const res = await delAssembleByIds(qs.stringify(data));

      this.$message.success("删除成功");
      this.selectPage = 1;
      this.initSelectList();
      this.getAssemblyNum();
      this.pageUuid = "";
      this.getList();
    },
    // 导入
    async importChecking(files) {
      const { file } = files;
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (suffix != "doc" && suffix != "docx") {
        this.$message({
          type: "error",
          message: "请选择docx或doc文档",
          customClass: "short-message",
          duration: 2000,
        });
        return;
      }
      var fromData = new FormData();
      fromData.append("type", "1");
      fromData.append("file", file);
      const res = await saveToCollect(fromData);
      if (res.code == 200) {
        this.uploadSuccess = true;
      }
    },
    hideSidebar() {
      this.$emit("hideSidebar");
    },
    goDisk() {
      this.uploadSuccess = false;
      this.$store.commit("ChangeSideBarId", 77);
      this.$router.push("/disk");
      this.$bus.$emit("changeNavIndex", "智能网盘");
    },
    // 添加汇编
    async addAssembly() {
      if (this.checkarr.length == 0) {
        this.$message({
          type: "error",
          message: "请选择文章！",
        });
        return;
      }
      let data = [];
      if (this.labelId == null || this.labelId == "undefined") {
        this.labelId = "WKlingdrssx00001";
      }

      this.checkarr.forEach((item) => {
        let obj = {};
        obj.docId = item;
        obj.docType = 1;

        data.push(obj);
      });
      const res = await addAssemblys({
        docInfoDTOList: data,
        labelId: this.stair ? this.stair : this.labelId,
      });
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: `${res.message}，您还可以添加 ${res.data} 篇文章`,
        });
        this.checkarr = [];
        this.getAssemblyNum();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    async deleteSelectAll() {
      console.log(this.tableSelectList);
      if (this.tableSelectList.length == 0) {
        return this.$message.error("请选择需要删除的选项");
      }
      const textMd5Str = this.tableSelectList
        .map((item) => {
          return item.textMd5;
        })
        .join(",");
      const data = {
        ids: textMd5Str,
      };
      const res = await delAssembleByIds(qs.stringify(data));

      this.$message.success("删除成功");
      this.tableSelectList = [];
      this.selectPage = 1;
      this.initSelectList();
      this.getAssemblyNum();
      this.pageUuid = "";
      this.getList();
    },
    //点击汇编成册,弹窗
    addCompileVolume() {
      if (this.isPlaying != 0) {
        return this.$message.error(
          "当前正在执行汇编成册 等待转换完成之后即可开始下一次汇编"
        );
      }
      if (this.assemblyNum == 0) {
        return this.$message("当前暂无文件可汇编成册");
      }
      this.compileVolumeDialog = true;
    },
    async clickPreview(item) {
      this.$emit("clickPreview", item);
      // const data = {
      //   md5Label: item.textMd5 + "/" + "WKxiyesd00001",
      //   originalId: item.userId,
      //   templateId: "",
      // };
      // // const loading = this.$createLoading("#infocompileIndex");
      // const loading = this.$loading({
      //   lock: true,
      //   text: "加载中",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.3)",
      // });
      // try {
      //   const resdata = await openLibraryFile(qs.stringify(data));
      //   loading.close();

      //   this.$refs["dataAnalysisDetails"].openLibraryFile = {
      //     ...resdata.data.obj,
      //     html: resdata.data.html,
      //     payStatus: resdata.data.status,
      //     url: resdata.data.url,
      //     viptip: resdata.data.viptip,
      //     // author: resdata.data.author,
      //     originalId: item.userId,
      //     md5Label:
      //       item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
      //     traceSourceDate: item.traceSourceDate,
      //     traceSourceEvaluation: item.traceSourceEvaluation,
      //     isTraceSource: item.isTraceSource,
      //   };
      //   this.$refs["dataAnalysisDetails"].openLibraryFile.author =
      //     resdata.data.author;
      //   this.isDetails = true;
      // } catch (e) {
      //   loading.close();
      // }
    },
    // 是否收藏
    async collect(item) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      let formData = new FormData();
      formData.append("textMd5", item.textMd5 + "/" + "WKxiyesd00001");
      const res = await Collection(formData);
      if (res.code === 200) {
        if (item.isMark == true) {
          this.$message({
            type: "success",
            message: "取消收藏",
          });
        } else {
          this.$message({
            type: "success",
            message: "收藏成功",
          });
        }
        if (this.isCollect) {
          this.collectlist();
        } else {
          this.getList();
        }
        // this.getList(this.labelId);
      } else if (res.code == 2001) {
        this.$store.commit("setLockClickText", this.currentClickItemVip);
        this.$bus.$emit("buyTipsLock");
        this.$message({
          type: "error",
          message: res.message,
        });
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    handleClick(item) {
      this.sortArr.forEach((item) => {
        item.colorSwitch = false;
      });
      item.colorSwitch = !item.colorSwitch;
      item.sort = !item.sort;
      if (item.title == "时间") {
        if (item.sort) {
          this.sort = "asc";
          this.sortType = "time_sort";
        } else {
          this.sort = "desc";
          this.sortType = "time_sort";
        }
      }
      if (item.title == "权威") {
        if (item.sort) {
          this.sort = "asc";
          this.sortType = "authoritative";
        } else {
          this.sort = "desc";
          this.sortType = "authoritative";
        }
      }
      if (item.title == "热度") {
        if (item.sort) {
          this.pageNum = 1;

          this.sort = "asc";
          this.sortType = "hot";
        } else {
          this.pageNum = 1;

          this.sort = "desc";
          this.sortType = "hot";
        }
      }

      if (this.isCollect) {
        this.pageNum = 1;
        this.collectlist();
      } else {
        this.pageNum = 1;
        this.pageUuid = "";
        this.getList(this.currentLabelId);
      }
    },
    selectRow(select, row) {
      console.log(select, row);
      this.tableSelectList = select;
    },
    selectCurrentChange(val) {
      this.selectPage = val;
      this.initSelectList();
    },
    //汇编成册
    AssemblyBooks() {
      this.compilations = true;
    },
    //选择页数
    currentChange(i) {
      this.$nextTick(() => {
        let PageId = document.querySelector("#infocompileIndex");

        PageId.scrollTop = 0;
      });
      this.checked = false;
      this.pageNum = i;
      if (this.isCollect) {
        this.collectlist();
      } else {
        window.document.documentElement.scrollTop = 0;
        this.getList();
      }
    },
    // 单文件下载
    async singleDownload(item) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      try {
        const res = await findTextUrl({
          textMd5: item.textMd5 + "/" + "WKxiyesd00001",
        });
        loading.close();
        if (res.code == 200) {
          const suffix = res.data.substr(res.data.lastIndexOf("."));
          // console.log(suffix);
          window.open(res.data);
        } else if (res.code == 2001) {
          this.$store.commit("setLockClickText", this.currentClickItemVip);
          this.$bus.$emit("buyTipsLock");
          this.$message({
            type: "error",
            message: res.message,
          });
          return;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } catch (e) {
        loading.close();
      }
    },
    async search() {
      this.pageNum = 1;
      this.pageUuid = "";
      await this.getList(this.currentLabelId);
    },
    async initSelectList() {
      const data = {
        page: this.selectPage,
        limit: 10,
      };
      this.dialogLoading = true;
      const res = await queryAssembleWord(data);
      this.dialogLoading = false;
      this.selectList = res.data.data;
      this.selectTotal = res.data.total;
    },
    shareClick(val) {
      this.QrCodeVisible = true;
      this.$nextTick(() => {
        this.qr = new QRCode(this.$refs.qrcode_box, {
          text: `https://mall.writemall.com/infoCompile?textMd5=${val.textMd5}&labelId=WKxiyesd00001&originalId=${val.userId}`,
          width: 130,
          height: 130,
          correctLevel: 3,
          colorDark: "#000000",
          colorLight: "#ffffff",
        });
      });
    },
    // 标准版单选
    handleChecked(item) {
      if (item.isAssembly) {
        return;
      }
      item.ischecked = !item.ischecked;
      if (item.ischecked) {
        this.checkarr.push(item.textMd5);
      } else {
        this.checkarr.pop();
      }
      this.$forceUpdate();
    },
  },
  components: {
    record,
    // dataAnalysisDetails,
  },
  mounted() {
    document.body.onclick = () => {
      // console.log(this.isShowHistoryList);
      if (this.isShowHistoryList) {
        this.isShowHistoryList = false;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.infoCompile {
  height: 100%;
  position: relative;
  // .dataAnalysisDetails {
  //   position: absolute;
  //   left: 0;
  //   top: 0px;
  //   height: 100%;
  //   z-index: 2003;
  //   background: #fff;
  //   width: 100%;
  // }
  .infocompileIndex {
    height: 100%;
    position: relative;
    height: 100%;
    // overflow: auto;
    box-sizing: border-box;
    flex: 1;
    .qrcode {
      ::v-deep .el-dialog {
        // top: 50%;
        // transform: translateY(-50%);
        margin-top: 20vh !important;
        width: 500px;
        height: 390px;
        background: #ffffff;
        border: 0px solid #818790;
        box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
        .el-dialog__header {
          height: 42px;
          background: #f4f6f7;
          padding: 0;
          line-height: 42px;
          .el-dialog__title {
            margin-left: 15px;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: #393939;
          }
          .el-dialog__headerbtn {
            top: $font-size-small;
            right: $font-size-small;
            font-size: $font-size-large;
          }
        }
        .el-dialog__body {
          padding: 65px 20px;
          .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .notes {
              font-size: $font-size-medium;
              font-family: PingFang SC;
              font-weight: 500;
              color: $color-text-black;
            }
            .imgBox {
              margin-top: 30px;
            }
          }
        }
      }
    }
    // 主题
    .banner {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 40px;
      // min-width: 780px;
      border: 1px solid #eaeef1;
      background: #fbfcfd;
      .item {
        display: flex;
        align-items: center;
        margin: 0 15px;
        font-size: 14px;
        cursor: pointer;
        color: #333;
        img {
          margin-right: 5px;
          width: 16px;
          height: 16px;
        }
      }
    }
    .search_box {
      // display: flex;
      // align-items: center;
      padding: 20px 30px 0;
      .historyList {
        font-size: 14px;
        font-family: "Microsoft YaHei" !important;
        width: calc(100% - 172px);
        box-sizing: border-box;
        border-left: 1px solid #ff6900;
        border-right: 1px solid #ff6900;
        border-bottom: 1px solid #ff6900;
        overflow: auto;
        margin-top: -7px;
        position: absolute;
        // bottom: -293px;
        z-index: 99;
        max-height: 300px;
        background-color: #fff;
        border-radius: 0 0 10px 10px;
        > div:first-child {
          margin-top: 7px;
        }
        > div {
          color: #606266;
          padding-left: 15px;
          // margin-left: 15px !important;
          box-sizing: border-box;
          width: 98%;
          height: 30px;
          line-height: 30px;
          // margin: 0 auto;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            // background: #eee;
            color: #ff6900;
          }
        }
        .option {
          display: flex;
          justify-content: flex-end;
          // position: absolute;
          // bottom: 0px;
          &:hover {
            color: #999;
          }
          > span:first-child {
            margin-right: 10px;
          }
          > span:hover {
            color: #ff6900;
          }
        }
      }
      .search {
        // margin-left: 10px;
        flex: 1;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        // padding: 5px 5px 5px 0;

        border-radius: 3px;
        border: 1px solid #ff6900;

        .search_left {
          padding-left: 10px;
          .el-select {
            ::v-deep .el-input {
              .el-input__inner {
                width: 100px;
                border: 0px;
              }
            }
          }
        }

        .inp {
          height: 26px;
          border-left: 1px solid #e4e4e4;
          padding-left: 20px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-default;
          flex: 1;
        }
        .search_btn {
          // width: 40px;
          height: 40px;
          padding: 0 10px;
          background: #ff6900;
          // @include backgroundGroup("~assets/images/idiom.png");
          @include flex-center();
          // border-radius: 0 5px 5px 0;
          cursor: pointer;
          > img:first-child {
            width: 52px;
            height: 22px;
            margin: 0 10px;
          }
          // .search_icon {
          //   width: 17px;
          //   height: 17px;
          //   @include backgroundGroup("~assets/images/idiom.jpg");
          // }
        }
      }

      .keyword_box {
        margin-top: 20px;
        display: flex;

        .keyword_item {
          font-size: 14px;
          color: #666666;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
    &-title {
      flex-wrap: wrap;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .infocompileIndex-title-item {
        cursor: pointer;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 400;
        width: 105px;
        text-align: center;
        color: $color-text-gray-s;
      }

      .infocompileIndex-title-item:hover {
        color: $color-text-active;
      }
    }

    &-content {
      width: 100%;
      margin: 20px auto 0;
      height: calc(100% - 160px);
      .kong {
        text-align: center;
        font-size: 14px;
        color: #999;
        margin: 10vh 0;
        img {
          width: 150px;
          height: 150px;
        }
      }
      .theme_box {
        padding: 10px 0 0;
        .title {
          font-weight: bold;
        }
        .theme_list {
          // margin: 15px 0 0;
          display: flex;
          flex-wrap: wrap;
          font-size: 13px;
          position: relative;
          .theme_item {
            cursor: pointer;
            padding: 5px;
            margin: 5px;

            &:hover {
              color: #ff6900;
              background-color: #ecf5ff;
              border-radius: 5px;
            }
          }

          .all {
            cursor: pointer;
            position: absolute;
            bottom: 10px;
            right: 5px;
          }

          .blue {
            color: #ff6900;
            background-color: #ecf5ff;
            border-radius: 5px;
          }
          .selected {
            color: #ff6900;
            background-color: #ecf5ff;
            border-radius: 5px;
          }
        }
      }

      &-breadcrumb {
        margin-top: 20px;
        display: flex;

        .infocompileIndex-content-breadcrumb-item {
          display: flex;
          height: 30px;
          background: #f3f4f6;
          border-radius: 15px;
          align-items: center;
          margin-left: 20px;
          cursor: pointer;

          span {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ff6900;
          }

          .item-title {
            margin-left: 15px;
            width: 56px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .item-num {
            margin-left: 5px;
          }

          i {
            margin-left: 5px;
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }

      &-list {
        margin-top: 10px;
        height: 100%;
        width: 100%;
        &-title {
          min-width: 520px;
          padding: 0 30px;
          display: flex;
          align-items: center;

          &-left {
            display: flex;
            align-items: center;
            margin-left: 10px;

            .left-item {
              display: flex;
              margin-right: 20px;
              cursor: pointer;

              ::v-deep .el-badge__content.is-fixed {
                top: 50%;
                right: 0px;
              }

              .left-item-icon {
                width: 14px;
                height: 14px;
                margin-right: 6px;
              }

              .icon {
                @include backgroundGroup("~assets/images/infoCompile-icon.png");
              }

              .add {
                @include backgroundGroup("~assets/images/infoCompile-add.png");
              }

              font-size: 14px;

              .download {
                margin-left: 20px;
                @include backgroundGroup("~assets/images/upload-gray.png");
              }
              .folder {
                @include backgroundGroup("~assets/images/folder.png");
              }
            }
          }

          ::v-deep .el-checkbox {
            .el-checkbox__inner {
              width: 15px;
              height: 15px;
              border-color: #ff6900;
            }

            .el-checkbox__label {
              font-size: $font-size-medium;

              font-weight: 500;
              color: $color-text-black;
            }
          }
          &-right {
            display: flex;
            align-items: center;
            padding: 0 20px;

            .right-item {
              display: flex;
              margin-right: 20px;
              cursor: pointer;

              .right-item-text {
                font-size: $font-size-medium;

                font-weight: 500;
                color: $color-text-black;
              }

              .right-item-icon {
                width: 9px;
                height: 13px;
                margin-left: 6px;
              }

              .up {
                @include backgroundGroup("~assets/images/up.png");
              }

              .down {
                @include backgroundGroup("~assets/images/down.png");
              }

              .fontColor {
                color: #ff6900;
              }
            }

            .right-item:last-of-type {
              margin-right: 0px;
            }
          }
        }

        &-content {
          margin-top: 20px;
          padding: 0 10px;
          height: calc(100% - 50px);
          // height: 100%;
          overflow: auto;
          .content-item {
            min-width: 550px;
            position: relative;
            .source_version {
              position: absolute;
              top: 20px;
              right: 5%;
              display: flex;
              align-items: center;
              font-size: 14px;
              cursor: pointer;
              img {
                width: 20px;
                height: 20px;
              }
            }
            .content-item-icon {
              position: absolute;
              left: 0px;
              top: 10px;
              width: 34px;
              height: 30px;
              display: none;
            }

            .reprint {
              @include backgroundGroup("~assets/images/info/reprint.png");
            }

            .starting {
              @include backgroundGroup("~assets/images/info/starting.png");
            }

            .content-item-like {
              width: 17px;
              height: 16px;
              cursor: pointer;
              margin-right: 10px;
            }

            .like {
              @include backgroundGroup("~assets/images/info/like.png");
            }

            .like-red {
              @include backgroundGroup("~assets/images/info/like-red.png");
            }

            .content-item-text {
              font-size: 14px;
              // font-family: PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 26px;
              padding: 20px;

              // span:last-of-type {
              //   color: #ff6900;
              //   cursor: pointer;
              //   text-decoration: underline;
              // }

              .fontBlue {
                color: #ff6900;
              }
            }

            .title {
              font-size: 14px;
              // padding: 20px 20px 0;
              padding: 20px 0 0 20px;
              display: flex;
              align-items: center;
              .introduce-checkBox {
                margin-right: 10px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .introduce-checkBox-icon {
                  width: 16px;
                  height: 16px;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-image: url("~assets/images/info/checkBox.png");
                  margin-right: 5px;
                }

                .checkBox-checked {
                  background-image: url("~assets/images/info/checkBox-checked.png");
                }
              }

              .title_box {
                flex: 1;
                font-size: 16px;
                color: #333333;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 150px;
                &:hover {
                  color: #ff6900;
                }
              }
            }

            .content-item-introduce {
              display: flex;
              align-items: center;
              font-size: 14px;
              // font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
              padding: 0 20px 20px;
              border-bottom: 1px solid #efefef;

              .introduce-radioBox {
                margin-left: 15px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .introduce-radioBox-icon {
                  width: 16px;
                  height: 16px;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-image: url("~assets/images/info/radio.png");
                  margin-right: 4px;
                }

                .radioBox-checked {
                  background-image: url("~assets/images/info/radio-checked.png");
                }
              }

              .introduce-starBox {
                margin-right: 15px;
                display: flex;
                align-items: center;

                .introduce-starBox-icon {
                  width: 14px;
                  height: 14px;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-image: url("~assets/images/info/star.png");
                  margin-right: 5px;
                }
              }

              .introduce-hotBox {
                margin-left: 18px;
                display: flex;
                align-items: center;

                .introduce-hotBox-icon {
                  width: 14px;
                  height: 14px;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-image: url("~assets/images/info/hot.png");
                  margin-right: 5px;
                }
              }

              .introduce-lampBox {
                display: flex;
                align-items: center;
                margin-right: 15px;

                .introduce-lampBox-icon {
                  width: 14px;
                  height: 14px;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-image: url("~assets/images/info/lamp.png");
                  margin-right: 4px;
                }
              }

              .introduce-timeBox {
                display: flex;
                align-items: center;

                .introduce-timeBox-icon {
                  width: 14px;
                  height: 14px;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-image: url("~assets/images/info/time.png");
                  margin-right: 4px;
                }

                .introduce-timeBox-text {
                  width: 85px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .introduce-titleBox-text {
                width: 120px;
                margin-left: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .content-item-btnGroup {
              display: flex;
              align-items: center;

              flex: 1;
              justify-content: space-between;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;

              .btnGroup-line {
                width: 1px;
                height: 30px;
                background: #d8d8d8;
              }

              .content-item-btnItem {
                cursor: pointer;
                display: flex;
                align-items: center;

                img {
                  margin-right: 3px;
                }

                .content-item-btnItem-icon {
                  width: 16px;
                  height: 16px;
                  background-repeat: no-repeat;
                  background-size: cover;
                  margin-right: 5px;
                }

                .download {
                  background-image: url("~assets/images/info/download.png");
                }

                .upload {
                  background-image: url("~assets/images/info/upload.png");
                }

                .relation {
                  background-image: url("~assets/images/info/relation.png");
                }

                .index {
                  background-image: url("~assets/images/info/index.png");
                }

                .copyright {
                  background-image: url("~assets/images/info/copyright.png");
                }

                .share {
                  background-image: url("~assets/images/info/share.png");
                }
              }

              .downloadBox:hover .download {
                background-image: url("~assets/images/info/download-red.png");
              }

              .uploadBox:hover .upload {
                background-image: url("~assets/images/info/upload-red.png");
              }

              .relationBox:hover .relation {
                background-image: url("~assets/images/info/relation-red.png");
              }

              .indexBox:hover .index {
                background-image: url("~assets/images/info/index-red.png");
              }

              .copyrightBox:hover .copyright {
                background-image: url("~assets/images/info/copyright-red.png");
              }

              .shareBox:hover .share {
                background-image: url("~assets/images/info/share-red.png");
              }

              .content-item-btnItem:hover .content-item-btnItem-text {
                color: #ec5e58;
              }
            }
          }

          .content-item:hover {
            background: #ffffff;
            box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
            border-radius: 5px;
          }
        }
      }
    }

    &-footer {
      @include noselect;
      // margin: 30px 0;
      display: flex;
      justify-content: center;

      ::v-deep .el-pagination {
        button {
          width: 80px;
        }
      }
    }

    .addFil {
      ::v-deep .el-dialog {
        .el-dialog__footer {
          .dialog-footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .footer-btn {
              cursor: pointer;
              width: 71px;
              height: 41px;
              border: 1px solid #cecece;
              border-radius: 3px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              background: #fff;
            }

            .sure {
              margin-right: 12px;
              background: #ff6900;
              color: #fff;
            }
          }
        }
      }
    }
    .compile-volume {
      ::v-deep .el-dialog {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
        margin-top: 20vh !important;
        width: 340px;
        height: 200px;
        background: #ffffff;
        box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
        .el-dialog__header {
          padding: 12px 12px 0px;
          .el-dialog__title {
            font-size: $font-size-small;
            font-family: PingFang SC;
            font-weight: 400;
            color: $color-text-gray;
          }
          .el-dialog__headerbtn {
            top: 12px;
            right: 12px;
          }
        }
        .el-dialog__body {
          display: flex;
          flex-direction: column;
          flex: 1;
          // padding: 35px 30;
          // width: 75%;
          .content {
            @include flex-center;
            flex: 1;
          }
          .footer {
            display: flex;
            justify-content: space-between;
            margin-top: 28px;
            padding: 20px 0;
            width: 100%;

            .footer-btn {
              @include noselect;
              font-size: $font-size-medium;
              font-family: PingFang SC;
              font-weight: 500;
              color: $color-bg-heightlight;
              cursor: pointer;
              &.clear {
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
}
</style>
