<template>
  <div class="stencilDetails">
    <!-- 主体 -->
    <div class="main">
      <!-- 内容 -->
      <div class="file_body">
        <div class="header-option">
          <div class="option">
            <div class="back" v-if="!isBack" @click="$emit('backClick')">
              <i class="el-icon-arrow-left"></i>返回
            </div>
            <div class="download" @click="download">下载Word版</div>
            <div class="download" @click="findPdfVersion">预览Word版</div>
          </div>
          <div class="obj">
            <div class="date">上传: {{ htmlText.createTime }}</div>
            <div class="hot">阅读: {{ htmlText.wordNum }}</div>
          </div>
        </div>
        <div
          class="file_body_html"
          style="line-height: 1.8; text-align: justify"
          v-html="htmlText.html"
        ></div>

        <div v-if="!htmlText.payStatus" class="blue" @click="goto">
          <img :src="require('assets/images/login-suo.png')" alt="" />
          预览结束，开通{{ htmlText.viptip }}会员全部模板可下载
        </div>
        <!-- 底部 -->
        <div class="bottom_box" v-if="!htmlText.payStatus">
          <div class="btn bgc" v-if="!htmlText.payStatus" @click="nowBuy">
            立即购买
          </div>
        </div>
      </div>
    </div>

    <!-- 购买弹窗 -->
    <el-dialog
      title="选择购买"
      :modal-append-to-body="false"
      :visible.sync="buyDialog"
      class="buyDialog"
    >
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in ChooseBuy"
          :key="index"
          :class="buyTypeI == index ? 'blue' : ''"
          @click="handleclick(index)"
        >
          <div class="title">
            <div class="name">{{ item.name }}</div>

            <div class="price">¥ {{ item.price }}</div>
          </div>

          <div class="introduce">
            {{ item.introduce }}
          </div>

          <div class="icon" v-show="buyTypeI == index">
            <img :src="require('assets/images/opt_for.png')" alt="" />
          </div>
        </div>
      </div>

      <div class="btn">
        <div class="start_btn">
          <el-button type="text" @click="purchase(ChooseBuy[buyTypeI])">
            {{
              buyTypeI == 1
                ? ChooseBuy[buyTypeI].price == 0
                  ? "添加"
                  : "购买"
                : "购买"
            }}
          </el-button>
          <div class="bian"></div>
          <el-button type="text" @click="buyDialog = false">取消</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 扫码支付 -->
    <el-dialog
      title="扫码支付"
      :close-on-click-modal="false"
      :visible.sync="paymentPopup"
      :modal-append-to-body="false"
      @close="handleClosePaymentPopup"
      class="payment"
    >
      <div class="content">
        <div class="notes">请使用微信扫码下方二维码完成支付</div>
        <div class="imgBox">
          <img :src="qrCodeurl.codeImg" alt="" />
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
        <div class="price">
          应付款：<span
            >￥<span>{{ qrCodeurl.orderPrice }}</span></span
          >
        </div>
      </div>
    </el-dialog>

    <!-- 评论 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="commentsDialog"
      title="评论该篇模板"
      @close="closeCommentsDialog"
      class="commentsDialog"
    >
      <div class="content">
        <div class="rate_box">
          <el-rate v-model="rate"></el-rate>
          评分 {{ rate }}
        </div>

        <div class="textarea">
          <el-input
            type="textarea"
            :rows="7"
            show-word-limit
            maxlength="500"
            placeholder="非常感谢您的真知灼见，我们将迅速落实并反馈。"
            v-model="textarea"
          >
          </el-input>
        </div>

        <div class="up_box">
          <templatetUpload ref="templatetUpload" />
        </div>
      </div>
      <div class="footer">
        <div class="footer-btn clear" @click="commentsDialog = false">取消</div>
        <div class="placeholder"></div>
        <div class="footer-btn" @click="commentsSubmit">提交</div>
      </div>
    </el-dialog>

    <!-- 图片放大 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="imgDialog"
      class="imgDialog"
    >
      <img width="100%" height="100%" :src="magnifyImgArr[imgIndex]" alt="" />
    </el-dialog>
    <AuthorizationInfo
      @PaySuccess="PaySuccess"
      ref="authorize"
      v-if="authorize"
    />

    <idiomTips
      @closeClick="isShow = false"
      @paySuccess="paySuccess"
      @NoCoupon="NoCoupon"
      ref="idiomTips"
      :isShow="isShow"
      :dataType="6"
    />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  wordToHtml,
  addTemplateHot,
  templateDownload,
  logAdd,
  commentList,
  otherorderAdd,
  commentAdd,
  chooseBuy,
  openTemplateFile,
  commentPraise,
} from "api/search";
import { pcOrderPay } from "api/common";
import qs from "qs";
import {
  payOtherOrderPay,
  PaywechatOtherOrderQueryQrcode,
} from "api/traceTheSource";
import { addUserLibrary } from "api/goodsGetails";
import { getToken } from "utils/auth";
import templatetUpload from "components/idiom/templatetUpload";
import AuthorizationInfo from "components/traceSource/TraceSourceAuthorizationInfoStencil";
import { previewUrl } from "api/common";
import { settings } from "api/service";
import idiomTips from "components/idiomTips";
export default {
  name: "stencilDetails",
  //import引入的组件需要注入到对象中才能使用
  components: {
    templatetUpload,
    AuthorizationInfo,
    idiomTips,
  },
  props: {
    item: {},
    isBack: {
      default: false,
    },
  },
  data() {
    //这里存放数据
    return {
      authorize: false,
      // 模板数据
      htmlText: "",
      // 评论
      commentsList: [],
      // 购买弹窗
      buyDialog: false,
      // 选中购买类型
      buyTypeI: 0,
      // 扫码弹窗
      paymentPopup: false,
      // 二维码URL
      qrCodeurl: {},
      //定时器
      timer: null,
      // 评论弹窗
      commentsDialog: false,
      // 评论文件对象
      itemTemplatet: {},
      // 默认评分
      rate: 5,
      // 评论内容
      textarea: "",
      magnifyImgArr: [],
      imgIndex: 0,
      imgDialog: false,
      // 模板选择购买
      ChooseBuy: [],
      closeTimer: null,
      payAliQRcode: "",
      // 成语券支付弹窗
      isShow: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 成语券支付成功
    paySuccess() {
      if (this.htmlText.userId != 0) {
        window.open(
          this.htmlText.url + "?attname=" + this.htmlText.title + ".docx"
        );
        this.isShow = false;
      } else {
        window.open(this.htmlText.url);
        this.isShow = false;
      }

      let data = {
        textMd5: this.htmlText.textMd5,
      };
      this.Addlog(data);
      // this.$message.success("快捷支付成语券成功");
    },
    NoCoupon(){
      this.isShow = true;
    },
    iscommentsDialog() {
      if (!this.htmlText.templateStatus) {
        this.buyDialog = true;
        this.getChooseBuy();
        return;
      }

      this.commentsDialog = true;
    },
    async PaySuccess() {
      const data = {
        md5Label: this.htmlText.md5Label,
        originalId: this.htmlText.author,
        // templateId: "",
      };
      const resdata = await openTemplateFile(qs.stringify(data));
      if (resdata.data.status) {
        this.htmlText = {
          ...resdata.data.obj,
          html: resdata.data.html,
          payStatus: resdata.data.status,
          url: resdata.data.url,
          viptip: resdata.data.viptip,
          author: resdata.data.author,
        };
        try {
          // const suffix = resdata.data.url.substr(
          //   resdata.data.url.lastIndexOf(".")
          // );
          // eslint-disable-next-line
          OpenDocument2(res.data.obj.title + ".docx", resdata.data.url);
        } catch (e) {
          console.log(e);
        }
      }
    },
    // 点赞
    async like(val) {
      const data = {
        targetId: val.id,
        type: 1,
      };

      const res = await commentPraise(data);
      // console.log(res);
      val.praiseNum = res.data;
      this.$forceUpdate(); //数据更新强制渲染页面
    },
    goto() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (this.htmlText.author == 0) {
        this.buyDialog = true;
        this.getChooseBuy();
      } else {
        let data = {
          textMd5: this.htmlText.textMd5,
        };
        this.authorize = true;
        this.$nextTick(() => {
          this.$refs["authorize"].authorization(data);
        });
      }
    },
    // 模板选择购买
    async getChooseBuy() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      let form = new FormData();
      form.append("md5Label", this.htmlText.md5Label);
      const res = await chooseBuy(form);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }

      this.ChooseBuy = res.data;
      if (this.htmlText.author != "") {
        this.ChooseBuy.pop();
      }
    },

    magnifyImg(urlArr, index) {
      this.imgIndex = index;
      this.magnifyImgArr = urlArr.map((item) => {
        return this.$store.state.onlineBasePath + item;
      });

      this.imgDialog = true;
    },
    errorImg(params) {
      params.headImg = require("assets/images/user.png");
    },
    // 评论弹窗关闭
    closeCommentsDialog() {
      this.textarea = "";
      this.rate = 5;
      this.$refs["templatetUpload"].img = [];
      this.$refs["templatetUpload"].arr = [];
    },

    // 提交评论
    async commentsSubmit() {
      const data = {
        templateId: this.id,
        content: this.textarea,
        star: this.rate,
        files: this.$refs["templatetUpload"].arr.toString(),
      };

      const res = await commentAdd(data);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "发布成功",
          duration: "1000",
        });

        this.getCommentList(this.id);
        this.commentsDialog = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
          duration: "1000",
        });
      }
    },

    handleclick(i) {
      console.log(i);
      this.buyTypeI = i;
    },

    // 添加
    async addStencil(productCode) {
      const data = {
        productCode,
      };

      const res = await addUserLibrary(data);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }
      this.buyDialog = false;
      this.getWordToHtml(this.htmlText.id);
    },

    // 购买
    purchase(val) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (this.buyTypeI == 0) {
        this.addOrder();
      }
      if (this.buyTypeI == 1) {
        let productId = this.htmlText.productId;
        if (val.price == 0) {
          return this.addStencil(val.obj.productCode);
        }
        console.log(this.htmlText);
        this.$router.push(
          "/details/1/" + (this.htmlText.currentClickItemVip.isVip - 1)
        );
        this.$bus.$emit("changeNavIndex", "会员服务");
      }
    },
    // 生成订单
    async addOrder() {
      const labelId = this.htmlText.md5Label.split("/")[1];
      const data = {
        platform: 1,
        producType: labelId == "MBshangyjhs00001" ? 5 : 4,
        pids: [this.item.textMd5],
      };

      const res = await otherorderAdd(data);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }

      this.QrCode(res.data.otherOrderId);
      // this.AlipaypcOrderPay(res.data.otherOrderId);
    },
    // 支付宝支付
    async AlipaypcOrderPay(oid) {
      const data = {
        oid,
      };

      const res = await pcOrderPay(qs.stringify(data));
      const divForm = document.getElementById("divForm");
      if (divForm) {
        document.body.removeChild(divForm);
      }
      const div = document.createElement("div");
      div.id = "divForm"; // 设置id
      div.innerHTML = res.data;
      document.body.appendChild(div);
      // 利用id去获取表单
      document
        .getElementById("divForm")
        .children[0].setAttribute("target", "_blank");
      document.getElementById("divForm").children[0].submit();
    },

    // 生成支付二维码
    async QrCode(oid) {
      let _this = this;
      const data = {
        oid,
      };

      const res = await payOtherOrderPay(data);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }
      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
        this.closeTimer = null;
      }
      this.paymentPopup = true;
      this.qrCodeurl = res.data;
      this.closeTimer = setTimeout(() => {
        clearInterval(this.timer);
        this.paymentPopup = false;
      }, 180000);
      this.timer = window.setInterval(() => {
        _this.wechatOrderQueryQrcode(oid);
      }, 3000);
    },

    // 调用微信支付的查询订单接口
    async wechatOrderQueryQrcode(oid) {
      const data = {
        oid,
      };
      const res = await PaywechatOtherOrderQueryQrcode(data);
      if (res.code == 200) {
        window.clearInterval(this.timer);
        this.$message({
          type: "success",
          message: "购买成功!",
        });

        this.buyDialog = false;
        this.paymentPopup = false;
        this.$emit("findBuyState", this.htmlText);
        // this.getWordToHtml(this.htmlText.id);
      }
    },

    // 关闭支付弹窗停止定时器
    handleClosePaymentPopup() {
      // console.log(1);
      window.clearInterval(this.timer);
    },

    // 插入模板
    insert() {
      if (!this.htmlText.templateStatus) {
        this.buyDialog = true;
        this.getChooseBuy();
        return;
      }
      try {
        // eslint-disable-next-line
        InsertDocument(
          this.$store.state.onlineBasePath + this.htmlText.templateUrl
        );
      } catch (e) {
        console.log(e);
      }
    },
    findPdfVersion() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (!this.htmlText.payStatus) {
        this.buyDialog = true;
        this.getChooseBuy();
        return;
      }
      previewUrl({
        url: this.htmlText.url,
      }).then((res) => {
        window.open(res.data);
      });
    },
    // 打开模板
    open() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (!this.htmlText.payStatus) {
        this.buyDialog = true;
        this.getChooseBuy();
      } else {
        previewUrl({
          url: this.htmlText.url,
        }).then((res) => {
          window.open(res.data);
        });
      }
    },

    // 获取模板
    async getWordToHtml(id) {
      let form = new FormData();
      form.append("templateId", id);
      const res = await wordToHtml(form);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }
      this.htmlText = res.data;
    },
    nowBuy() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (this.htmlText.author == 0) {
        this.buyDialog = true;
        this.getChooseBuy();
      } else {
        let data = {
          textMd5: this.htmlText.textMd5,
        };
        this.authorize = true;
        this.$nextTick(() => {
          this.$refs["authorize"].authorization(data);
        });
      }
    },
    // 获取评论
    async getCommentList(val) {
      const data = {
        templateId: val,
      };

      const res = await commentList(data);
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }
      this.commentsList = res.data;
    },

    // 下载
    async download() {
      let arr = {
        textMd5: this.htmlText.textMd5,
      };

      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      if (!this.htmlText.payStatus) {
        if (this.htmlText.author == 0) {
          this.buyDialog = true;
          this.getChooseBuy();
        } else {
          let data = {
            textMd5: this.htmlText.textMd5,
          };
          this.authorize = true;
          this.$nextTick(() => {
            this.$refs["authorize"].authorization(data);
          });
        }
      } else {
        if (this.htmlText.userId != 0) {
          let res = await this.Addlog(arr);
          if (res) {
            window.open(
              this.htmlText.url + "?attname=" + this.htmlText.title + ".docx"
            );
          }
        } else {
          let res = await this.Addlog(arr);

          if (res) {
            window.open(this.htmlText.url);
          }
        }
      }
      // if (this.htmlText.templateStatus == false) {
      //   this.$message({
      //     message: "开通会员全部模板可下载",
      //   });

      //   return;
      // }

      // try {
      //   // eslint-disable-next-line
      //   DownloadDocument(
      //     this.htmlText.template,
      //     this.$store.state.onlineBasePath + this.htmlText.templateUrl
      //   );
      // } catch (e) {
      //   console.log(e);
      // }

      // this.addHot(this.htmlText.id);
    },

    // 添加模板下载日志
    async Addlog(arr) {
      const res = await logAdd(arr);
      if (res.code == 200) {
        return true;
      } else if (res.code == 2012) {
        // this.$message.error(res.message);
        this.$refs["idiomTips"].getUserinfo();
        let res1 = await settings();
        if (res1.data.aiImg == 0) {
          this.isShow = true;
        } else {
          await this.$refs["idiomTips"].ticketPay();
          // this.$message.success("快捷支付成语券成功");
        }

        return false;
      } else {
        // this.$message.error(res.message);
        return false;
      }
    },

    async TemplateDownload(labelId) {
      const params = {
        labelId,
      };

      const res = await templateDownload(params);
    },

    // 热度+1
    async addHot(id) {
      let formData = new FormData();
      formData.append("tempId", id);
      const res = await addTemplateHot(formData);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.getWordToHtml();
    // this.getCommentList(this.id);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.stencilDetails {
  // overflow-x: hidden !important;
  .main {
    padding-bottom: 70px;
    .return {
      border-top: solid 1px #e6e6e6;
      background-color: #fbfcfd;
      border-bottom: solid 1px #e6e6e6;
      display: flex;
      .back {
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 20px;

        img {
          margin-right: 15px;
          height: 15px;
        }
      }

      .operation {
        display: flex;
        align-items: center;
        .item {
          font-size: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:hover {
            border-radius: 5px;
            background-color: #ecf5ff;
          }
        }
      }
    }

    .title_box {
      margin-top: 50px;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 20px 50px;
      .imgIcom {
        width: 50px;
        margin-right: 20px;
      }
      .content {
        flex: 1;
      }
      .text {
        font-size: 15px;
        font-weight: bold;
      }
    }

    .file_body {
      position: relative;
      .header-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid #c3c3c3;
        background: #fff;
        position: sticky;
        top: 0px;
        .option {
          display: flex;
          align-items: center;
          color: #fe8103;
          > div {
            margin: 0 10px;
            cursor: pointer;
            font-size: 14px;
          }
        }
        .obj {
          > div {
            margin: 0 10px;
          }
        }
      }
      .obj {
        // position: absolute;
        // right: 50px;
        // top: 20px;
        font-size: 12px;

        color: #c3c3c3;
        display: flex;
        justify-content: space-between;
      }
      margin: 20px 50px 20px;
      // border: 1px solid #e8e8e8;
      box-shadow: 0 2px 10px #ccc;
      padding: 20px 50px;

      ::v-deep .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #e6e6e6;
      }

      .file_body_html {
        ::v-deep div {
          overflow-x: hidden;
          p {
            line-height: 1.8;
          }

          .bt-subscribe-article {
            font-size: 15px !important;
            line-height: 1.8;
          }

          span {
            font-size: 15px !important;
          }
        }
      }

      .blue {
        margin: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        cursor: pointer;
        img {
          margin-right: 10px;
        }
      }

      .comments_list {
        .kong {
          height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: #888888;
          img {
            width: 150px;
            height: 150px;
            margin-top: 0px;
            margin-bottom: 20px;
          }
        }
        .item {
          margin-top: 20px;
          display: flex;
          .item_avatar {
            display: flex;
            width: 80px;
            margin-right: 30px;
            align-items: center;
            flex-direction: column;

            img {
              margin-bottom: 15px;
              width: 30px;
              height: 30px;
            }

            .name {
              font-size: 13px;
              width: 100%;
              text-align: center;
            }
          }

          .item_content {
            flex: 1;
            .item_score {
              .rate_box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .date {
                  font-size: 12px;
                  color: #888888;
                  line-height: 12px;
                }
                ::v-deep .el-rate {
                  .el-rate__item {
                    .el-rate__icon {
                      font-size: 20px;
                    }
                  }
                }
              }
            }

            .item_text {
              margin-top: 20px;
              font-size: 14px;
              line-height: 1.8;
            }

            .item_imglist {
              margin: 25px 0;
              img {
                border: 1px solid #e9e9e9;
                width: 80px;
                height: 80px;
                margin-right: 20px;
              }
            }

            .item_like {
              display: flex;
              flex-direction: row-reverse;
              img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }
              .like {
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #888888;
              }
            }
          }
        }
      }
    }

    .bottom_box {
      background-color: #fff;
      border-top: 1px solid #eee;
      text-align: center;
      // position: fixed;
      bottom: 0;
      width: 100%;
      margin: 0 auto;
      // display: flex;
      padding: 10px 0;
      box-sizing: border-box;
      // justify-content: space-around;
      align-items: center;
      position: sticky;
      bottom: 0;
      .like,
      .download {
        cursor: pointer;
        font-size: 13px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        img {
          margin-bottom: 5px;
          width: 20px;
        }
      }

      .btn {
        cursor: pointer;
        border-radius: 25px;
        color: #fff;
        padding: 10px 25px;
        width: 60%;
        margin: 0 auto;
        text-align: center;
        background-color: #fea802;
      }

      .bgc {
        background-color: #fe8103;
      }
    }
  }
  // 购买弹窗
  .buyDialog {
    ::v-deep .el-dialog {
      width: 600px;

      .el-dialog__header {
        text-align: center;
        font-weight: bold;
      }
    }
    .content {
      .item {
        cursor: pointer;
        box-sizing: border-box;
        padding: 20px;
        position: relative;
        .title {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 20px;
          .name {
            width: 85%;
          }
          .price {
            margin-left: 10px;
            color: red;
          }
        }

        .introduce {
          font-size: 15px;
        }

        .icon {
          position: absolute;
          right: 0;
          bottom: 0;

          img {
            width: 20px;
            display: block;
          }
        }
      }

      .blue {
        border: 1px solid #4587ff;
      }
    }

    .btn {
      margin-top: 20px;
      .btn_box {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .el-button {
          color: #4587ff;
          border: 1px solid #4587ff;

          &:nth-child(2) {
            color: #999999;
            border: 1px solid #999999;
          }
        }
      }

      .start_btn {
        display: flex;
        align-items: center;
        .el-button {
          padding: 15px;
          width: 100%;
          font-size: 16px;
        }
        .bian {
          height: 20px;
          width: 1px;
          background-color: #e8e8e8;
        }
      }
    }
  }

  // 支付弹窗
  .payment {
    ::v-deep .el-dialog {
      margin-top: 20vh !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            width: 110px;
            height: 110px;
            margin-top: 30px;
            img {
              width: 110px;
              height: 110px;
            }
          }
          .price {
            margin-top: 30px;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: #393939;
            span {
              color: #eb441e;
              span {
                font-size: $font-size-large;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  // 评论弹窗
  .commentsDialog {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      width: 500px;

      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 20px 20px 0px;
        .el-dialog__title {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        width: 500px;
        min-height: 350px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px;
        .content {
          .rate_box {
            display: flex;
            align-items: center;

            .el-rate {
              margin-right: 10px;
            }
          }
          .textarea {
            margin-top: 20px;
            .el-textarea {
              .el-textarea__inner {
                resize: none;
              }
            }
          }
        }
        .footer {
          display: flex;
          justify-content: space-between;
          margin-top: 28px;
          padding: 0 40px;
          .footer-btn {
            @include noselect;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
            &.clear {
              color: #999999;
            }
          }

          .placeholder {
            width: 1px;
            height: 20px;
            background: #e8e8e8;
          }
        }
      }
    }
  }
}
</style>
