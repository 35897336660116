<template>
  <div class="box">
    <div class="outBox">
      <div class="twenty_big leftFlex" v-show="!isDetails">
        <div class="top_img">
          <img :src="require('assets/images/TwentyBig.png')" alt="" />
          <div class="text">党的二十大专栏</div>
        </div>
        <div class="container">
          <div class="sidebar" :class="{ hide: isHide }">
            <el-menu ref="elMenu" unique-opened :collapse-transition="false">
              <!-- <el-menu-item index="1" @click.native="handleClick"
                >语境算法引擎</el-menu-item
              > -->
              <el-submenu
                v-for="(item, index) in sidebarList"
                :index="String(item.id)"
                :key="item.id"
                :class="[
                  item.contentNum == 0 ? 'charNumClass' : '',
                  sidebarTtemId == item.id ? 'pitchOn' : '',
                ]"
                @click.native.stop="findChild(item, index)"
              >
                <template slot="title">
                  {{ item.title }}
                </template>
                <el-menu-item
                  v-for="(item1, index1) in item.children"
                  :key="item1.id"
                  :index="index + '-' + index1"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  @click.native.stop="getClicktheme(item1, 1)"
                  >{{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
          <!-- 第二级 -->
          <div
            class="sidebar"
            :class="{ isHide: isHide, absolute: !ChildrenInfoShow }"
            v-show="ChildrenInfo.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              :unique-opened="true"
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo.isShow = false;
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo.parentName }}
              </el-menu-item>
              <el-submenu
                :hide-timeout="0"
                :show-timeout="0"
                :index="item.labelId"
                v-for="item in ChildrenInfo.list"
                @click.native="
                  () => {
                    getClicktheme(item, 2);
                  }
                "
                :class="[
                  sidebarTtemId == item.id ? 'pitchOn' : '',
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                :key="item.id"
                :title="item.conName.length > 7 ? item.conName : ''"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getClicktheme(item1, 3);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第三级 -->
          <div
            class="sidebar"
            :class="{ isHide: isHide, absolute: !ChildrenInfoShow }"
            v-show="ChildrenInfo1.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              :unique-opened="true"
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo1.isShow = false;
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo1.parentName }}
              </el-menu-item>
              <el-submenu
                :hide-timeout="0"
                :show-timeout="0"
                :index="item.labelId"
                v-for="item in ChildrenInfo1.list"
                :title="item.conName.length > 7 ? item.conName : ''"
                :class="[
                  sidebarTtemId == item.id ? 'pitchOn' : '',
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                @click.native="
                  () => {
                    getClicktheme(item, 2);
                  }
                "
                :key="item.id"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      if (isAn) {
                        ChildrenInfoShow = true;
                      } else {
                        ChildrenInfoShow = false;
                      }
                      getClicktheme(item1, 4);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>

          <!-- 第四级 -->
          <div
            class="sidebar"
            :class="{ isHide: isHide, absolute: !ChildrenInfoShow }"
            v-show="ChildrenInfo2.isShow"
          >
            <el-menu
              default-active="-1"
              :collapse-transition="false"
              :unique-opened="true"
            >
              <el-menu-item
                index="-1"
                @click="
                  () => {
                    ChildrenInfo2.isShow = false;
                  }
                "
              >
                <i class="el-icon-arrow-left"></i>{{ ChildrenInfo2.parentName }}
              </el-menu-item>
              <el-submenu
                :hide-timeout="0"
                :show-timeout="0"
                :index="item.labelId"
                v-for="item in ChildrenInfo2.list"
                :title="item.conName.length > 7 ? item.conName : ''"
                :class="[
                  sidebarTtemId == item.id ? 'pitchOn' : '',
                  item.contentNum == 0 ? 'charNumClass' : '',
                  !item.isSubset ? 'isSubsetClass' : '',
                ]"
                @click.native="
                  () => {
                    getClicktheme(item, 2);
                  }
                "
                :key="item.id"
              >
                <template slot="title"> {{ item.conName }}</template>
                <el-menu-item
                  :index="item1.labelId"
                  v-for="item1 in item.children"
                  :key="item1.id"
                  :class="item1.contentNum == 0 ? 'charNumClass' : ''"
                  :title="item1.conName.length > 7 ? item1.conName : ''"
                  @click.native.stop="
                    () => {
                      getClicktheme(item1, 5);
                    }
                  "
                >
                  {{ item1.conName }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
          <div class="main">
            <component
              :is="currentComponent"
              @hideSidebar="hideSidebar"
              @clickPreview="clickPreview"
              @stencilClick="stencilClick"
              :isHide="isHide"
              ref="children"
            ></component>
          </div>
        </div>
      </div>
      <div class="dataAnalysisDetails" v-show="isDetails">
        <div v-show="!isStencil">
          <dataAnalysisDetails
            ref="dataAnalysisDetails"
            @backClick="backClick"
          />
        </div>
        <div class="isStencil" v-show="isStencil">
          <stencilDetails
            @findBuyState="findBuyState"
            @backClick="backClick"
            ref="stencilDetails"
          />
        </div>
      </div>

      <div class="right recommendRightList">
        <recommend />
      </div>
    </div>
    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
import infoCompile from "./infoCompile";
import dataAnalysisDetails from "../dataAnalysis/dataAnalysisDetails.vue";
import stencilDetails from "views/stencil/stencilDetails";
import stencil from "./stencil";
import goldenSentence from "./goldenSentence";
import recommend from "components/recommend/recommend.vue";
import { getlibrarylist } from "api/dropDownList";
import qs from "qs";
import { openTemplateFile } from "api/search";
import commonFooter from "components/footer/commonFooter.vue";
import { openLibraryFile, openLibraryTraceSourceFile } from "api/infoCompile";
// synopsis
import synopsis from "./synopsis";
export default {
  data() {
    return {
      currentIndex: 0,
      isHide: false,
      sidebarTtemId: 2,
      currentComponent: "infoCompile",
      isAn: false,
      isDetails: false,
      ChildrenInfoShow: false,
      isStencil: false,
      ChildrenInfo: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo1: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo2: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      sidebarList: [
        // {
        //   id: 1,
        //   labelId: "",
        //   title: "语境算法引擎",
        // },
        {
          id: 2,
          labelId: "WKxiyesd00001",
          title: "专栏文库",
          children: [],
        },
        {
          id: 3,
          labelId: "MBxuexddesdjs00001",
          title: "专栏模板",
          children: [],
        },
        {
          id: 4,
          labelId: "JJdangdesd00001",
          title: "专栏金句",
          children: [],
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.defaultOpen();
  },
  methods: {
    backClick() {
      this.isDetails = false;
    },
    async clickPreview(item) {
      const data = {
        md5Label: item.textMd5 + "/" + "WKxiyesd00001",
        originalId: item.userId,
        templateId: "",
      };
      // const loading = this.$createLoading("#infocompileIndex");
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      try {
        const resdata = await openLibraryFile(qs.stringify(data));
        loading.close();

        this.$refs["dataAnalysisDetails"].openLibraryFile = {
          ...resdata.data.obj,
          html: resdata.data.html,
          payStatus: resdata.data.status,
          url: resdata.data.url,
          viptip: resdata.data.viptip,
          // author: resdata.data.author,
          originalId: item.userId,
          md5Label:
            item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
          traceSourceDate: item.traceSourceDate,
          traceSourceEvaluation: item.traceSourceEvaluation,
          isTraceSource: item.isTraceSource,
        };
        window.document.documentElement.scrollTop = 0;
        this.$refs["dataAnalysisDetails"].openLibraryFile.author =
          resdata.data.author;
        this.isDetails = true;
        this.isStencil = false;
      } catch (e) {
        loading.close();
      }
    },
    findBuyState() {},
    async stencilClick(item, isBuy) {
      console.log(item);
      if (isBuy != 1) {
        this.stencilId = item;
      }

      const data = {
        md5Label: item.textMd5 + "/" + "MBxuexddesdjs00001",
        originalId: item.userId,
        templateId: "",
      };
      // const loading = this.$createLoading("#main");
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      try {
        const res = await openTemplateFile(qs.stringify(data));

        loading.close();

        this.$refs["stencilDetails"].htmlText = {
          ...res.data.obj,
          html: res.data.html,
          payStatus: res.data.status,
          url: res.data.url,
          viptip: res.data.viptip,
          author: res.data.author,
          md5Label:
            item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
          hot: item.hot,
          currentClickItemVip: this.currentClickItemVip,
        };
        this.isDetails = true;
        this.isStencil = true;
        console.log(this.isDetails);
        window.document.documentElement.scrollTop = 0;
      } catch (e) {
        loading.close();
      }
    },
    handleClick() {
      this.$refs["elMenu"].close("2");
      this.currentComponent = "synopsis";
      this.sidebarTtemId = -1;
      // this.$refs["elMenu"].close("3");
      // this.$refs["elMenu"].close("4");
    },
    hideSidebar() {
      this.isHide = !this.isHide;
    },
    async defaultOpen() {
      const data = {
        // parentId: item.id,
        labelId: "WKxiyesd00001",
        parentId: 7,
      };
      let res = await getlibrarylist(qs.stringify(data));

      if (res.code == 200) {
        this.$set(this.sidebarList[0], "children", res.data);
        this.$refs["elMenu"].open("2");
        // console.log(item);
      }
    },

    async getClicktheme(item, i) {
      // console.log(111);
      window.document.documentElement.scrollTop = 0;
      this.$refs["children"].pageNum = 1;
      this.$refs["children"].checked = false;
      this.$refs["children"].searchValue = "";
      this.$refs["children"].pageUuid = "";
      this.$refs["children"].getList(item.labelId);
      const data = {
        labelId: item.labelId,
        parentId: 7,
      };

      if (i == 1) {
        if (item.isSubset) {
          this.ChildrenInfo.isShow = true;
        }

        if (this.ChildrenInfo.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo1.isShow = false;
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo1.list = [];
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo.parentName = item.conName;
        this.ChildrenInfo.labelId = item.labelId;
      } else if (i == 3) {
        if (item.isSubset) {
          this.ChildrenInfo1.isShow = true;
        }

        if (this.ChildrenInfo1.labelId == item.labelId) {
          return;
        } else {
          this.ChildrenInfo2.isShow = false;
          this.ChildrenInfo2.list = [];
        }

        this.ChildrenInfo1.parentName = item.conName;
        this.ChildrenInfo1.labelId = item.labelId;
      } else if (i == 4) {
        if (item.isSubset) {
          this.ChildrenInfo2.isShow = true;
        }

        if (this.ChildrenInfo2.labelId == item.labelId) {
          return;
        }

        this.ChildrenInfo2.parentName = item.conName;
        this.ChildrenInfo2.labelId = item.labelId;
      }
      if (i == 2) {
        if (item.children.length != 0) {
          return;
        }
      }

      const res = await getlibrarylist(qs.stringify(data));

      if (i == 1) {
        this.ChildrenInfo.list = res.data;
      } else if (i == 3) {
        this.ChildrenInfo1.list = res.data;
      } else if (i == 4) {
        this.ChildrenInfo2.list = res.data;
      }

      if (i == 2) {
        item.children = res.data;
        this.$forceUpdate();
      }
      // this.themeList = res.data;
    },

    findChildinfo(item) {
      this.sidebarTtemId = -1;
      this.$refs["children"].searchValue = "";
      if (this.$refs.children.pageNum) {
        this.$refs.children.pageNum = 1;
      }

      this.$refs["children"].checked = false;
      this.$refs["children"].pageUuid = "";
      this.$refs["children"].getList(item.labelId);
    },
    async findChild(item) {
      this.$refs["children"].checked = false;
      this.$refs.elMenu.activeIndex = null;
      this.sidebarTtemId = item.id;
      console.log(item);

      if (item.id == 1) {
        this.currentComponent = "synopsis";
      } else if (item.id == 2) {
        this.currentComponent = "infoCompile";
        this.$refs["children"].pageUuid = "";
        this.$nextTick(() => {
          this.$refs["children"].getList();
        });
      } else if (item.id == 3) {
        this.currentComponent = "stencil";
        this.$refs["children"].pageUuid = "";

        this.$nextTick(() => {
          this.$refs["children"].getList();
        });
      } else {
        this.currentComponent = "goldenSentence";
        this.$refs["children"].pageUuid = "";
        this.$nextTick(() => {
          this.$refs["children"].getList();
        });
      }

      if (item.children.length != 0) {
        return;
      }
      const data = {
        // parentId: item.id,
        labelId: item.labelId,
        parentId: 7,
      };
      let res = await getlibrarylist(qs.stringify(data));

      if (res.code == 200) {
        this.$set(item, "children", res.data);
        // console.log(item);
      }
    },
  },
  components: {
    infoCompile,
    stencilDetails,
    stencil,
    synopsis,
    goldenSentence,
    recommend,
    commonFooter,
    dataAnalysisDetails,
  },
};
</script>

<style lang="scss" scoped>
.pitchOn {
  ::v-deep .el-submenu__title {
    box-sizing: border-box;
    border-right: #ff6900 4px solid;
  }
}
.absolute {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
}
.box {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;
}
.outBox {
  display: flex;
  margin: 0 auto;
  width: 1338px;
  background: #fff;
}
.twenty_big {
  // display: flex;
  width: 936px;
  margin-right: 20px;
  background: #fff;
  // height: calc(100vh - 50px);
  .top_img {
    height: 172px;
    position: relative;
    .text {
      color: #fceca7;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    // height: calc(100% - 172px);
    display: flex;
    position: relative;
  }
  .sidebar {
    min-width: 190px;
    // height: calc(100vh - 100px);
    // overflow-y: auto;
    border-right: solid 1px #e6e6e6;
    .door {
      width: 100%;
      height: 60px;
      cursor: pointer;
    }
    .red {
      color: red;
    }
    .el-menu {
      border-right: 0px;
      .isSubsetClass {
        ::v-deep .el-submenu__title {
          // cursor: not-allowed;
          // color: #ccc;
          .el-submenu__icon-arrow {
            display: none;
          }
        }
      }

      .charNumClass {
        ::v-deep .el-submenu__title {
          cursor: not-allowed;
          color: #ccc;
        }
        cursor: not-allowed;
        color: #ccc;
      }
      .submenu {
        ::v-deep .el-submenu__title {
          cursor: not-allowed;
          color: #ccc;
          .el-submenu__icon-arrow {
            display: none;
          }
        }
      }

      .elMenuItem {
        cursor: not-allowed;
        color: #ccc;
      }
      .el-menu-item {
        height: 25px;
        padding: 0 10px 0 0;
        line-height: 25px;
        font-size: 14px;
        -webkit-transition: none;
        transition: none;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        align-items: center;
        white-space: nowrap;
        min-width: 190px;
        position: relative;

        .el-icon-arrow-left {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 0px;
          font-size: 14px;
        }
        &:hover {
          color: #ff6900;
          border-right: #ff6900 4px solid;
        }
      }
      .el-menu-item.is-active {
        border-right: #ff6900 4px solid;
        background-color: #ffbf6914;
      }
    }
    ::v-deep .el-submenu {
      .el-submenu__title {
        display: flex;
        align-items: center;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        white-space: nowrap;

        .vipIcon {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 40px;
          margin-left: 0px;
        }
        img {
          width: 13px;

          margin-left: 5px;
        }
        &:hover {
          color: #ff6900;
        }
      }
      .el-menu {
        .is-active {
          border-right: #ff6900 4px solid;
        }
      }
    }

    .img1 {
      width: 20px;
      margin-right: 10px;
    }
    .img2 {
      width: 20px;
      margin-left: 10px;
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
  .main {
    height: 100%;
    width: calc(100vw - 190px);
    min-width: 590px;
  }
}
.right {
  flex: 1;
  background: #fff;
}
</style>
