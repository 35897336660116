<template>
  <div class="synopsis">
    <div class="header">党的二十大语境算法引擎简介</div>
    <div class="info">
      党的二十大语境算法引擎，是以党的二十大为主题，基于二十大的党代会报告、重要讲话、会议决议、要闻报道、党代表、伟大成就、解读文章、评论文章、综述等公开信息数据，以学习、宣传、贯彻落实党的二十大精神和部署作为语言文字表达环境，融合新一代内容算法、搜索引擎算法训练而成的语境算法模型，是智能推文的最佳搭档。
    </div>
    <div class="one">
      <div class="title">1.下载“成语极速版”使用</div>
      <img class="yjk01" :src="require('assets/images/yjk01.png')" alt="" />
      <div class="download">
        下载地址: <span @click="download">成语极速版(windows系统).zip</span>
      </div>
      <div>（1）选择算法引擎</div>
      <img class="yjk02" :src="require('assets/images/yjk02.png')" alt="" />
      <div>（2）选中文本，点击按钮，将根据选中文本推荐下一句内容</div>
      <img class="yjk04" :src="require('assets/images/yjk04.png')" alt="" />
      <div>
        （3）点击
        <img :src="require('assets/images/goldenSentence/add.png')" alt="" />
        ，插入到鼠标点击位置。例如微信输入框：
      </div>
      <img class="yjk03" :src="require('assets/images/yjk03.png')" alt="" />
      <div>
        【提示】需要鼠标点击到微信输入框，再点击“<img
          :src="require('assets/images/goldenSentence/add.png')"
          alt=""
        />”
      </div>
      <div class="footer">
        （4）点击“
        <img :src="require('assets/images/idiom/001.png')" alt="" />
        ”，复制推荐文本
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    download() {
      try {
        const link = document.createElement("a");
      link.href = "https://qiniu.writemall.com/ChengyuSpeedEdition/%E6%88%90%E8%AF%AD%E6%9E%81%E9%80%9F%E7%89%88%28windows%E7%B3%BB%E7%BB%9F%29.zip";
      link.download = "download";
      link.click();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.synopsis {
  color: #333333;
  padding: 0 50px;
  .header {
    height: 150px;
    line-height: 150px;
    text-align: center;

    font-size: 16px;
    font-weight: bold;
  }
  .info {
    font-size: 12px;
    text-indent: 2em;
    line-height: 30px;
  }
  .one {
    .download {
      span {
        cursor: pointer;
        color: #ff6900;
      }
    }
    .footer {
      height: 100px;
    }
    font-size: 12px;
    .yjk01 {
      width: 196px;
      height: 31px;
    }
    .yjk02 {
      width: 211px;
      height: 237px;
    }
    .yjk04 {
      width: 210px;
      height: 271px;
    }
    .yjk03 {
      width: 302px;
      height: 136px;
    }
    img {
      width: 16px;
      height: 16px;
    }
    > div {
      margin: 30px 0;
    }
    .title {
      font-weight: bold;
      font-size: 12px;
      margin: 30px 0;
    }
  }
}
</style>
