<template>
  <div class="stencil">
    <div class="main" id="main" v-show="!isDetails">
      <!-- 搜索 -->

      <div class="search_box">
        <div class="search_left">
          <el-select v-model="selectTheme" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <input
          class="inp"
          type="text"
          placeholder="请输入内容搜索"
          v-model="searchValue"
          @keyup.enter="search"
        />
        <div class="search_btn" @click="search()">
          <img :src="require('assets/images/idiom.png')" alt="" />
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>
      </div>
      <div v-show="!isflag" style="height: 83%">
        <!-- 排序 -->
        <div class="sort_box">
          <div
            v-show="!isMark"
            class="right-item"
            v-for="(item, index) in sortArr"
            :key="index"
            @click="sortClick(item)"
          >
            <div
              class="right-item-text"
              :class="item.colorSwitch == true ? 'fontColor' : ''"
            >
              {{ item.title }}
            </div>
            <i
              class="right-item-icon"
              :class="item.sort == true ? 'up' : 'down'"
            ></i>
          </div>
          <!-- <el-upload
            class="upload-demo"
            action=""
            :show-file-list="false"
            :http-request="importChecking"
          >
            <div class="right-item">
              <i class="left-item-icon download"></i>
              <div class="right-item-text">上传文件</div>
            </div>
          </el-upload>
          <div class="right-item" @click="goDisk">
            <i class="left-item-icon folder"></i>
            <div class="right-item-text">打开文件夹</div>
          </div> -->
        </div>

        <!-- 模板列表 -->
        <div class="stencil_list" v-show="listData.length != 0">
          <div
            class="stencil_item"
            @click="stencilClick(v)"
            v-for="(v, i) in listData"
            :key="i"
          >
            <img class="img" :src="require('assets/images/wps.png')" alt="" />

            <div class="content">
              <div class="title">{{ v.footNotes }}</div>
              <div class="obj">
                <div class="date">上传: {{ v.publishTime }}</div>
                <div class="hot">热度: {{ v.hot }}</div>
                <div class="size">
                  <!-- 评分: {{ v.star.toFixed(1) }} -->
                </div>
              </div>
            </div>

            <div class="hover_box animate__animated animate__bounceInRight">
              <div class="vip">{{ remark || "免费" }}</div>
            </div>

            <img
              class="imgIcon"
              @click.stop="collectionClick(v)"
              :src="
                v.isMark == true
                  ? require('assets/images/goldenSentence/collection-red.png')
                  : require('assets/images/goldenSentence/collection.png')
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 空 -->
      <div class="kong" v-if="isflag">
        <img :src="require('assets/images/kong.png')" alt="" />

        <div class="txt">更新中</div>
      </div>

      <el-pagination
        v-if="listData.length != 0"
        style="margin-left: 20px"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[15, 20, 25, 30]"
        :pager-count="5"
        :current-page="pageNum"
        :page-size="limit"
        @current-change="currentChange"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getProductListGroup,
  gettemplateAndSayingLibrary,
  getlibrarylist,
} from "api/dropDownList";
import stencilDetails from "views/stencil/stencilDetails";
import qs from "qs";
import { previewUrl } from "api/common";
import { getToken } from "utils/auth";
import { debounce } from "utils/utils";
import {
  findOftenLibraryType,
  findOftenWord,
  addTemplateCollect,
  addTemplateHot,
  logAdd,
  templateSearch,
  openTemplateFile,
  findUserCollectTemplate,
} from "api/search";
export default {
  name: "stencil",
  props: {
    isHide: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // stencilDetails,
  },
  data() {
    return {
      sidebarTtemId: "",
      isflag: false,
      currentClickItemVip: null,
      isMark: false,
      allremark: "",
      defaultItem: null,
      stair: "MBgongwxz00001",
      isAn: false,
      ChildrenInfoShow: false,
      uploadSuccess: false,
      ChildrenInfo: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo1: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      ChildrenInfo2: {
        parentName: "",
        isShow: false,
        labelId: "",
        list: [],
      },
      selectTheme: "2",
      options: [
        {
          value: "1",
          label: "全文",
        },
        {
          value: "2",
          label: "标题",
        },
      ],
      pageUuid: "",
      labelId: "",
      // 库列表
      bankList: [],
      // isHide: false,
      // 收藏
      collect: [],
      // 需要展开的数组
      openeds: [],
      // 搜索关键字
      searchValue: "",

      // id
      themeId: "",
      libraryId: "",
      // 主题索引
      themeI: -1,
      // 模板列表
      listData: [],
      // 页码
      pageNum: 1,
      limit: 8,
      total: 0,
      isDetails: false,
      stencilId: "",
      remark: "",
      sortArr: [
        //排序数组
        {
          title: "时间",
          sort: false, //true 升序 false降序
          id: 1,
          colorSwitch: true,
        },
        {
          title: "热度",
          sort: true,
          id: 2,
          colorSwitch: false,
        },
        // {
        //   title: "评分",
        //   sort: true,
        //   id: 5,
        // },
        // {
        //   title: "评论",
        //   sort: true,
        //   id: 6,
        // },
      ],
      sortType: 1,
      sortVal: "desc",
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    // 获取模板列表
    async getList(labelId) {
      const data = {
        page: this.pageNum,
        labelId: labelId ? labelId : "MBxuexddesdjs00001",
        key: this.searchValue,
        pageUuid: this.pageUuid,
        findField: this.selectTheme,

        sortType: this.sortType == 1 ? "create_time" : "hot",
        sort: this.sortVal,
      };

      const res = await templateSearch(qs.stringify(data));
      if (res.code != 200) {
        this.$message({
          message: res.message,
        });
        return;
      }

      this.total = res.data.totalNum;
      this.listData = res.data.data;
      if (this.listData.length != 0) {
        this.isflag = false;
      } else {
        this.isflag = true;
      }
      this.pageUuid = res.data.page_uuid;
    },
    hideSidebar() {
      this.$emit("hideSidebar");
    },
    // 页码
    currentChange(val) {
      this.pageNum = val;
      if (this.isMark) {
        this.collectlist();
      } else {
        window.document.documentElement.scrollTop = 0;
        this.getList();
      }
    },
    backClick() {
      this.isDetails = false;
    },
    // 收藏
    collectionClick: debounce(async function (val) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });
        return window.loginFun();
      }
      let data = {
        textMd5: val.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
      };
      const res = await addTemplateCollect(data);
      if (res.code == 4) {
        this.$store.commit("setLockClickText", this.currentClickItemVip);
        this.$bus.$emit("buyTipsLock");
        this.$message({
          type: "error",
          message: res.message,
        });
        return;
      }
      if (res.code == 8) {
        this.stencilClick(val);
        this.$message({
          type: "error",
          message: res.message,
        });
        return;
      }
      this.$message({
        type: "success",
        message: val.isMark ? "取消收藏！" : "收藏成功！",
        duration: "1000",
      });
      val.isMark = !val.isMark;
      if (this.isMark) {
        this.collectlist();
      }
      this.$forceUpdate();
    }, 300),
    // 排序方法
    sortClick(item) {
      this.sortArr.forEach((item) => {
        item.colorSwitch = false;
      });
      item.colorSwitch = !item.colorSwitch;
      item.sort = !item.sort;
      if (item.sort) {
        this.sortVal = "asc";
      } else {
        this.sortVal = "desc";
      }
      this.sortType = item.id;
      this.pageNum = 1;
      this.pageUuid = "";
      this.getList();
    },
    // 搜索
    async search() {
      this.listData = [];
      this.pageNum = 1;
      this.pageUuid = "";
      await this.getList();
    },
    findBuyState() {},
    // 模板详情
    async stencilClick(item, isBuy) {
      this.$emit("stencilClick", item);
      // console.log(item);
      // if (isBuy != 1) {
      //   this.stencilId = item;
      // }

      // const data = {
      //   md5Label: item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
      //   originalId: item.userId,
      //   templateId: "",
      // };
      // // const loading = this.$createLoading("#main");
      // const loading = this.$loading({
      //   lock: true,
      //   text: "加载中",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.3)",
      // });
      // try {
      //   const res = await openTemplateFile(qs.stringify(data));

      //   loading.close();

      //   this.$refs["stencilDetails"].htmlText = {
      //     ...res.data.obj,
      //     html: res.data.html,
      //     payStatus: res.data.status,
      //     url: res.data.url,
      //     viptip: res.data.viptip,
      //     author: res.data.author,
      //     md5Label:
      //       item.textMd5 + "/" + (this.stair ? this.stair : this.labelId),
      //     hot: item.hot,
      //     currentClickItemVip: this.currentClickItemVip,
      //   };
      //   this.isDetails = true;
      //   console.log(this.isDetails);
      //   window.document.documentElement.scrollTop = 0;
      // } catch (e) {
      //   loading.close();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.stencil {
  // 主体区域
  .main {
    border-top: solid 1px #e6e6e6;
    flex: 1 1 0%;
    // padding: 20px;
    // padding: ;
    box-sizing: border-box;
    height: calc(100vh - 250px);
    overflow: auto;
    .banner {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 40px;

      border: 1px solid #eaeef1;
      background: #fbfcfd;
      .item {
        display: flex;
        align-items: center;
        margin: 0 15px;
        font-size: 14px;
        cursor: pointer;
        color: #333;
        img {
          margin-right: 5px;
          width: 16px;
          height: 16px;
        }
      }
    }
    // 搜索
    .search_box {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      // margin: 20px;
      margin: 20px 30px 0;
      // border-radius: 10px;
      border-radius: 3px;
      border: 1px solid #ff6900;
      position: relative;
      .hideBtn {
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
      }
      .search_left {
        padding-left: 10px;
        .el-select {
          ::v-deep .el-input {
            .el-input__inner {
              width: 100px;
              border: 0px;
            }
          }
        }
      }
      .inp {
        height: 26px;
        border-left: 1px solid #e4e4e4;
        padding-left: 20px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-default;
        flex: 1;
      }
      .search_btn {
        height: 40px;
        padding: 0 10px;
        background: #ff6900;
        @include flex-center();
        // border-radius: 0 0px 0px 0;
        cursor: pointer;
        > img:first-child {
          width: 52px;
          height: 22px;
          margin: 0 10px;
        }
      }
    }

    // 主题
    .theme_box {
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      // padding: 20px 0 0;
      width: 190px;
      height: calc(100vh - 51px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      .title {
        font-weight: bold;
      }
      .theme_lists {
        // border-right: solid 1px #e6e6e6;
        box-sizing: border-box;
      }
      .theme_list {
        font-size: 14px;
        .theme_item {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          align-items: center;
          white-space: nowrap;
          cursor: pointer;
          height: 25px;
          line-height: 25px;
          padding: 0 0 0 20px;

          &:hover {
            color: #ff6900;
            border-right: #ff6900 4px solid;
            background-color: #ecf5ff;
          }
        }

        .all {
          cursor: pointer;
          position: absolute;
          bottom: 10px;
          right: 5px;
        }

        .selected {
          color: #ff6900;
          background-color: #ecf5ff;
          border-right: #ff6900 4px solid;
        }
      }
    }

    // 排序
    .sort_box {
      padding: 20px 30px;
      font-size: 14px;
      display: flex;
      align-items: center;
      .right-item {
        display: flex;
        margin-right: 40px;
        cursor: pointer;

        .right-item-text {
          font-weight: 500;
        }

        .right-item-icon {
          width: 9px;
          height: 13px;
          margin-left: 6px;
        }

        .up {
          @include backgroundGroup("~assets/images/up.png");
        }

        .down {
          @include backgroundGroup("~assets/images/down.png");
        }
        .download {
          // margin-left: 20px;
          @include backgroundGroup("~assets/images/upload-gray.png");
        }
        .folder {
          margin-left: 20px;
          @include backgroundGroup("~assets/images/folder.png");
        }
        .left-item-icon {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
        .fontColor {
          color: #ff6900;
        }
      }

      .right-item:last-of-type {
        margin-right: 0px;
      }
    }

    // 模板列表
    .stencil_list {
      height: calc(100% - 40px);
      overflow: auto;
      .stencil_item {
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 15px 30px;
        cursor: pointer;
        position: relative;

        &:hover {
          border-radius: 5px;
          color: #000;
          background-color: #ecf5ff;

          .hover_box {
            right: 5px;
            box-sizing: border-box;
            padding: 5px 0;
            justify-content: space-between;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }

        .img {
          width: 50px;
          margin-right: 20px;
        }
        .imgIcon {
          position: absolute;
          bottom: 15px;
          right: 15px;
          width: 20px;
        }
        .content {
          width: 70%;
          .title {
            font-size: 14px;
            line-height: 1.5;
            font-weight: bold;
            margin-bottom: 15px;
          }
          .obj {
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .hover_box {
          display: none;
          position: absolute;
          right: -90px;

          font-size: 12px;

          .vip {
            padding: 5px;
            border-radius: 5px;
            background-color: #fdb33b;
            color: #fff;
          }
        }
      }
    }

    // 空
    .kong {
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 14px;
      color: #999;
      img {
        width: 150px;
        height: 150px;
      }

      .txt {
        margin-top: 30px;
      }
    }

    .el-pagination {
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
